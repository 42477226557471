export enum LangEnum {
  fr = 'fr',
  en = 'en',
  it = 'it',
  es = 'es',
}

export enum LocaleEnum {
  fr_FR = 'fr_FR',
  en_GB = 'en_GB',
  es_ES = 'es_ES',
  it_IT = 'it_IT',
}

export enum TextLangEnum {
  fr = 'Français',
  en = 'English',
  es = 'Español',
  it = 'Italiano',
}

export const DEFAULT_LOCALE_BY_LANGUAGE: Record<LangEnum, LocaleEnum> = {
  [LangEnum.fr]: LocaleEnum.fr_FR,
  [LangEnum.en]: LocaleEnum.en_GB,
  [LangEnum.it]: LocaleEnum.it_IT,
  [LangEnum.es]: LocaleEnum.es_ES,
};

export const DEFAULT_TEXT_BY_LANGUAGE: Record<LangEnum, TextLangEnum> = {
  [LangEnum.fr]: TextLangEnum.fr,
  [LangEnum.en]: TextLangEnum.en,
  [LangEnum.es]: TextLangEnum.es,
  [LangEnum.it]: TextLangEnum.it,
};
