<section class="context-drive-driving-school">
  <ng-container *ngIf="(isMobileAndTabletPortrait$ | async) === false; else mobileContext">
    <div class="context-drive-driving-school__card">
      <h2 class="context-drive-driving-school__title">
        <span class="context-drive-driving-school__title--blue" i18n>Inscrivez-vous</span>
        <ng-container i18n>
          et choisissez WIZBII Drive pour constituer votre dossier d&apos;inscription auprès de l&apos;auto-école de
          votre choix&nbsp;!</ng-container
        >
      </h2>
    </div>
    <img
      class="context-drive-driving-school__icon"
      src="/account/assets/images/context-drive-home/computer.svg"
      alt="WIZBII drive"
      matSuffix
    />
  </ng-container>
</section>

<ng-template #mobileContext>
  <p class="context-drive-driving-school__pop-up" i18n>
    <span class="highlighted highlighted--blue">Inscrivez-vous</span> et testez une série de code de la route
    <span class="highlighted highlighted--blue">gratuitement&nbsp;!</span>&nbsp;🚗
  </p>
</ng-template>
