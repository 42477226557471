import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Select } from '@ngxs/store';
import { SessionState } from '@stores/session/session.state';
import { CountryService } from '@wizbii/utils/models';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  @Select(SessionState.currentApp)
  currentApp$: Observable<CountryService>;

  @Select(SessionState.appIdIsNexity)
  appIdIsNexity$: Observable<boolean>;

  @Select(SessionState.hasCurrentApp)
  hasCurrentApp$: Observable<boolean>;
}

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [LogoComponent],
  exports: [LogoComponent],
})
export class LogoModule {}
