export enum FeaturesRoutingEnum {
  SignIn = 'sign-in',
  SignUp = 'sign-up',
  LostPassword = 'lost-password',
  ResetPassword = 'reset-password',
  Profile = 'profile',
  Dashboard = 'dashboard',
  Privacy = 'privacy',
  Security = 'security',
  Notifications = 'notifications',
  Unsubscribe = 'unsubscribe',
  Logout = 'logout',
}
