<p class="cnil-mension" bottom>
  <ng-container *ngIf="!showCnilText">
    <p class="extra-content">
      <ng-container *ngIf="extraContent" i18n> {{ extraContent }} </ng-container>
    </p>

    <p [ngSwitch]="appId">
      <ng-container *ngSwitchCase="'jobs'" i18n>
        Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par WIZBII, SAS au
        capital de 133 228 euros, immatriculée au RCS de Grenoble sous le numéro 524 455 540, dont le siège social se
        situe 31 rue Gustave Eiffel 38000 Grenoble (« WIZBII ») pour vous permettre d’accéder à des offres d’emploi, de
        stage et d’alternance, de postuler à ces offres, et également pour vous informer des dernières nouveautés
        proposées par WIZBII et ses partenaires privilégiés. WIZBII utilisera également ces données pour effectuer des
        statistiques et pour vous informer des dernières nouveautés proposées par WIZBII et ses partenaires privilégiés
        qui pourraient vous intéresser. La base légale du traitement est l’exécution du contrat pour l’accès aux offres
        et notre intérêt légitime pour les autres...
      </ng-container>

      <ng-container *ngSwitchCase="'permii-app'" i18n>
        Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par WIZBII, SAS au
        capital de 133 228 euros, immatriculée au RCS de Grenoble sous le numéro 524 455 540, dont le siège social se
        situe 31 rue Gustave Eiffel 38000 Grenoble (« WIZBII ») pour vous permettre d’acheter la formule de formation
        que vous désirez, de l'utiliser et de vous informer des dernières nouveautés proposées par WIZBII (cela peut
        également couvrir des prestations partenaires mais aucune donné ne leur sera transmise, toute sollicitation sera
        effectuée par WIZBII). La base légale du traitement est l’exécution du contrat pour l’achat et l’utilisation de
        votre formule de formation au code de la...
      </ng-container>

      <ng-container *ngSwitchCase="'money-intl'" i18n>
        Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par WIZBII, SAS au
        capital de 133 228 euros, immatriculée au RCS de Grenoble sous le numéro 524 455 540, dont le siège social se
        situe 31 rue Gustave Eiffel 38000 Grenoble (« WIZBII ») avec pour finalité principale de vous permettre de
        connaître les aides nationales, régionales, départementales et/ou municipales auxquelles vous pouvez prétendre.
        WIZBII utilisera également ces données pour effectuer des statistiques et pour vous informer des dernières
        nouveautés proposées par WIZBII et ses partenaires privilégiés qui pourraient vous intéresser. La base légale du
        traitement est l'exécution du contrat pour l'accès aux aides et notre intérêt légitime pour les autres...
      </ng-container>

      <ng-container *ngSwitchCase="'fibii'" i18n>
        Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par WIZBII, SAS au
        capital de 133 228 euros, immatriculée au RCS de Grenoble sous le numéro 524 455 540, dont le siège social se
        situe 31 rue Gustave Eiffel 38000 Grenoble (« WIZBII ») avec pour finalité principale de vous permettre de
        connaître les aides nationales, régionales, départementales et/ou municipales auxquelles vous pouvez prétendre.
        WIZBII utilisera également ces données pour effectuer des statistiques et pour vous informer des dernières
        nouveautés proposées par WIZBII et ses partenaires privilégiés qui pourraient vous intéresser. La base légale du
        traitement est l'exécution du contrat pour l'accès aux aides et notre intérêt légitime pour les autres...
      </ng-container>

      <ng-container *ngSwitchDefault i18n>
        Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par WIZBII, SAS au
        capital de 133 228 euros, immatriculée au RCS de Grenoble sous le numéro 524 455 540, dont le siège social se
        situe 31 rue Gustave Eiffel 38000 Grenoble (« WIZBII ») pour vous permettre d'accéder à l'ensemble des services
        de WIZBII, et également pour vous informer des dernières nouveautés proposées par WIZBII et ses partenaires
        privilégiées. WIZBII utilisera également ces données pour effectuer des statistiques et pour vous informer des
        dernières nouveautés proposées par WIZBII et ses partenaires privilégiés qui pourraient vous intéresser. La base
        légale du traitement est l'exécution du contrat pour l'accès aux offres et notre intérêt légitime pour les
        autres...
      </ng-container>

      <button class="see-more" (click)="showCnilText = !showCnilText" type="button" i18n>Voir plus</button>
    </p>
  </ng-container>

  <ng-container *ngIf="showCnilText">
    <ng-container [ngSwitch]="appId">
      <ng-container *ngSwitchCase="'jobs'">
        <p i18n>
          Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par WIZBII, SAS
          au capital de 133 228 euros, immatriculée au RCS de Grenoble sous le numéro 524 455 540, dont le siège social
          se situe 31 rue Gustave Eiffel 38000 Grenoble (« WIZBII ») pour vous permettre d’accéder à des offres
          d’emploi, de stage et d’alternance, de postuler à ces offres, et également pour vous informer des dernières
          nouveautés proposées par WIZBII et ses partenaires privilégiés. WIZBII utilisera également ces données pour
          effectuer des statistiques et pour vous informer des dernières nouveautés proposées par WIZBII et ses
          partenaires privilégiées qui pourraient vous intéresser. La base légale du traitement est l’exécution du
          contrat pour l’accès aux offres et notre intérêt légitime pour les autres finalités.
        </p>
      </ng-container>

      <ng-container *ngSwitchCase="'permii-app'">
        <p i18n>
          Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par WIZBII, SAS
          au capital de 133 228 euros, immatriculée au RCS de Grenoble sous le numéro 524 455 540, dont le siège social
          se situe 31 rue Gustave Eiffel 38000 Grenoble (« WIZBII ») pour vous permettre d’acheter la formule de
          formation que vous désirez, de l'utiliser et de vous informer des dernières nouveautés proposées par WIZBII
          (cela peut également couvrir des prestations partenaires mais aucune donné ne leur sera transmise, toute
          sollicitation sera effectuée par WIZBII). La base légale du traitement est l’exécution du contrat pour l’achat
          et l’utilisation de votre formule de formation au code de la route et le consentement pour les sollicitations
          de notre part.
        </p>
      </ng-container>

      <ng-container *ngSwitchCase="'money-intl'">
        <p i18n>
          Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par WIZBII, SAS
          au capital de 133 228 euros, immatriculée au RCS de Grenoble sous le numéro 524 455 540, dont le siège social
          se situe 31 rue Gustave Eiffel 38000 Grenoble (« WIZBII ») avec pour finalité principale de vous permettre de
          connaître les aides nationales, régionales, départementales et/ou municipales auxquelles vous pouvez
          prétendre. WIZBII utilisera également ces données pour effectuer des statistiques et pour vous informer des
          dernières nouveautés proposées par WIZBII et ses partenaires privilégiés qui pourraient vous intéresser. La
          base légale du traitement est l'exécution du contrat pour l'accès aux aides et notre intérêt légitime pour les
          autres finalités.
        </p>
      </ng-container>

      <ng-container *ngSwitchCase="'fibii'">
        <p i18n>
          Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par WIZBII, SAS
          au capital de 133 228 euros, immatriculée au RCS de Grenoble sous le numéro 524 455 540, dont le siège social
          se situe 31 rue Gustave Eiffel 38000 Grenoble (« WIZBII ») avec pour finalité principale de vous permettre de
          connaître les aides nationales, régionales, départementales et/ou municipales auxquelles vous pouvez
          prétendre. WIZBII utilisera également ces données pour effectuer des statistiques et pour vous informer des
          dernières nouveautés proposées par WIZBII et ses partenaires privilégiés qui pourraient vous intéresser. La
          base légale du traitement est l'exécution du contrat pour l'accès aux aides et notre intérêt légitime pour les
          autres finalités.
        </p>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <p i18n>
          Les informations recueillies sur ce formulaire sont enregistrées dans un fichier informatisé par WIZBII, SAS
          au capital de 133 228 euros, immatriculée au RCS de Grenoble sous le numéro 524 455 540, dont le siège social
          se situe 31 rue Gustave Eiffel 38000 Grenoble (« WIZBII ») pour vous permettre d'accéder à l'ensemble des
          services de WIZBII, et également pour vous informer des dernières nouveautés proposées par WIZBII et ses
          partenaires privilégiées. WIZBII utilisera également ces données pour effectuer des statistiques et pour vous
          informer des dernières nouveautés proposées par WIZBII et ses partenaires privilégiés qui pourraient vous
          intéresser. La base légale du traitement est l'exécution du contrat pour l'accès aux offres et notre intérêt
          légitime pour les autres finalités.
        </p>
      </ng-container>
    </ng-container>

    <p i18n>
      Les données collectées seront communiquées aux seuls destinataires suivants : les services internes de WIZBII
      ayant besoin d’en connaître ; les sociétés OVH et Google en qualité d’hébergeurs des données ; la société Sendgrid
      en qualité de CRM.
    </p>

    <p i18n>
      Les données sont conservées pendant la durée de notre relation contractuelle. Vous pouvez accéder aux données vous
      concernant, les rectifier, demander leur effacement, exercer votre droit à la limitation du traitement de vos
      données, procéder au retrait de votre consentement au traitement de vos données ; vous pouvez également vous
      opposer au traitement de vos données ; et vous pouvez enfin, exercer votre droit à la portabilité de vos données.
    </p>

    <p i18n>Consultez le site cnil.fr pour plus d’informations sur vos droits.</p>

    <p i18n>
      Pour exercer ces droits ou pour toute question sur le traitement de vos données dans ce dispositif, vous pouvez
      contacter : dpo&#64;wizbii.com
    </p>

    <p>
      <ng-container i18n>
        Si vous estimez, après nous avoir contactés, que vos droits « Informatique et Libertés » ne sont pas respectés,
        vous pouvez adresser une réclamation à la CNIL.
      </ng-container>

      <button class="see-more" (click)="showCnilText = !showCnilText" i18n type="button">Voir moins</button>
    </p>
  </ng-container>
</p>
