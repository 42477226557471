import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NotificationsService } from '@commons/notifications/notifications.service';
import { NotificationsComponent } from '@commons/notifications/notifications/notifications.component';

@NgModule({
  declarations: [NotificationsComponent],
  imports: [CommonModule, MatIconModule],
  exports: [NotificationsComponent],
  providers: [NotificationsService],
})
export class NotificationsModule {
  static forRoot(): ModuleWithProviders<NotificationsModule> {
    return {
      ngModule: NotificationsModule,
      providers: [NotificationsService],
    };
  }
}
