<section class="context-jobs">
  <ng-container *ngIf="(isMobileAndTabletPortrait$ | async) === false; else mobileContext">
    <h2 class="context-jobs__title" i18n>Prêt(e) à découvrir plus en détail ce poste&nbsp;?</h2>
    <p class="context-jobs__subtitle" i18n>Bon courage dans vos candidatures&nbsp;🤞</p>

    <div *ngIf="job$ | async as job" class="card-job">
      <wzb-company-logo class="card-job__logo" [company]="job.company" size="large" />
      <p class="card-job__location">{{ job.company.name }} • {{ job.location.city }}</p>

      <h4 class="card-job__title">{{ job.title }}</h4>
      <div class="card-job__skills">
        <p *ngFor="let skill of job.skills; trackBy: trackBySkill" class="card-job__skill">{{ skill }}</p>
      </div>
    </div>

    <div class="card-wizbii-jobs">
      <h4 class="card-wizbii-jobs__title" i18n>WIZBII Jobs c'est&nbsp;:</h4>
      <ul>
        <li class="card-wizbii-jobs__list-item" i18n>
          Plus de <span class="highlighted">150 000 jobs</span> à pouvoir
        </li>
        <li class="card-wizbii-jobs__list-item" i18n>
          <span class="highlighted">Des centaines</span> d'entreprises qui recrutent chaque jour
        </li>
        <li class="card-wizbii-jobs__list-item" i18n>
          <span class="highlighted">Plus de 100 évènements</span> de recrutements dans la France entière
        </li>
      </ul>
    </div>
  </ng-container>
</section>

<ng-template #mobileContext>
  <p class="context-jobs__pop-up" i18n>
    <span class="highlighted highlighted--green">Trouvez dès à présent</span> votre prochain stage, alternance ou nouvel
    emploi&nbsp;!&nbsp;💼
  </p>
</ng-template>
