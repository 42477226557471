import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { RouterModule } from '@angular/router';
import { FeaturesRoutingEnum } from '@features/feature-routing-enum';
import { trackEvent } from '@wizbii/utils/tracking';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {
  FeaturesRoutingEnum = FeaturesRoutingEnum;
  trackEvent = trackEvent;
}

@NgModule({
  declarations: [SidebarComponent],
  imports: [CommonModule, RouterModule, MatListModule, MatRippleModule, MatTooltipModule, MatIconModule],
  exports: [SidebarComponent],
})
export class SidebarModule {}
