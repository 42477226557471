/* eslint-disable no-console */
import '@angular/common/locales/global/en';
import '@angular/common/locales/global/es';
import '@angular/common/locales/global/fr';
import '@angular/common/locales/global/it';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { CoreModule } from '@core/core.module';
import { environment } from '@environment';

if (environment.isProdBuild) {
  enableProdMode();
}

const startModule = () =>
  platformBrowserDynamic()
    .bootstrapModule(CoreModule)
    .catch((err) => console.error(err));

document.addEventListener('DOMContentLoaded', () => {
  startModule();
});
