<section class="context-drive-home-2">
  <ng-container *ngIf="(isMobileAndTabletPortrait$ | async) === false; else mobileContext">
    <h2 class="context-drive-home-2__title">
      <span class="highlighted--white" i18n>Inscrivez-vous</span>&nbsp;et testez une série de code de la route
      <span class="highlighted--white" i18n>gratuitement&nbsp;!</span>
    </h2>

    <div class="context-drive-home-2__cards">
      <div class="card">
        <p class="card__title" i18n="@@context.drive.formation">Formation complète</p>
      </div>
      <div class="card">
        <p class="card__title" i18n="@@context.drive.lesson">Cours thématiques</p>
      </div>
      <div class="card">
        <p class="card__title" i18n="@@context.drive.license">Mode examen blanc</p>
      </div>
      <div class="card">
        <p class="card__title" i18n="@@context.drive.progress">Suivi de progression</p>
      </div>
    </div>
  </ng-container>
</section>

<ng-template #mobileContext>
  <p class="context-drive-home-2__pop-up" i18n>
    <span class="highlighted highlighted--blue">Inscrivez-vous</span> et testez une série de code de la route
    <span class="highlighted highlighted--blue">gratuitement&nbsp;!</span>&nbsp;🚗
  </p>
</ng-template>
