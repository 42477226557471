import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  ErrorHandler,
  HostBinding,
  HostListener,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { trackingConfig } from '@config/tracking-config';
import { BreakpointsService } from '@core/services/breakpoints/breakpoints.service';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/feature-routing-enum';
import { DashboardLayoutComponent } from '@features/layouts/dashboard-layout/dashboard-layout.component';
import { SignUpComponent } from '@features/sign-up/sign-up/sign-up.component';
import { ResizeObserver } from '@juggle/resize-observer';
import { LocaleEnum } from '@models/commons/locales.enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { RouterState } from '@ngxs/router-plugin';
import { Select, Store } from '@ngxs/store';
import { ServicesState } from '@stores/services/services.state';
import { SessionState } from '@stores/session/session.state';
import { WINDOW } from '@wizbii-utils/angular/core';
import { AccountWebservice } from '@wizbii-utils/angular/webservices';
import { WebVitalsOptions, WebVitalsParams, sendWebVitals } from '@wizbii/utils';
import { CountryService } from '@wizbii/utils/models';
import { setupTracking, track, trackEvent } from '@wizbii/utils/tracking';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable, Subscription, combineLatest, from, fromEvent, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, pairwise, switchMap, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-core',
  templateUrl: './core.component.html',
  styleUrls: ['./core.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoreComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * Use class `hover-on` in CSS as follows:
   * `:host-context(.hover-on) .link:hover { ... }`
   */
  @HostBinding('class.hover-on') hoverEnabled = true;

  @HostBinding('class.hover-off')
  get hoverDisabled(): boolean {
    return !this.hoverEnabled;
  }

  /**
   * Class `focus-off` disables all outlines automatically.
   */
  @HostBinding('class.focus-off') focusOutlineDisabled = false;

  get isHome(): boolean {
    return this.router.url === '/' || this.router.url.match(/^\/[a-zA-Z]{2}_[a-zA-Z]{2}/g)?.length > 0;
  }

  get isNotLoggedRoute(): boolean {
    return this.isHome || this.notLoggedRoutes.some((route) => this.router.url.includes(route));
  }

  get isLoginLoading(): boolean {
    return this.router.url.startsWith(`/${FeaturesRoutingEnum.SignIn}/process`);
  }

  private readonly notLoggedRoutes = [
    FeaturesRoutingEnum.SignIn,
    FeaturesRoutingEnum.LostPassword,
    FeaturesRoutingEnum.ResetPassword,
  ];

  showInterservicesHeader$: Observable<boolean>;
  subscriptions: Subscription[] = [];

  private targetObserve: any;
  private observer: ResizeObserver;
  marginTopFooter$ = new BehaviorSubject<string>('0rem');
  isSignup$ = new BehaviorSubject<boolean>(false);
  bannerIsVisible$ = new BehaviorSubject<boolean>(false);
  profileUrl$ = new BehaviorSubject<string>('');
  privacyUrl$ = new BehaviorSubject<string>('');
  securityUrl$ = new BehaviorSubject<string>('');
  notificationsUrl$ = new BehaviorSubject<string>('');
  trackingIsSetup = false;

  cookieName = 'preferred-locale';
  cookieExpiryName = 'preferred-locale_expiry';

  appId = environment.applicationId;
  apiDomain = environment.domain;
  contactEmailKey = environment.contactEmailKey;
  localesHandled = Object.values(LocaleEnum);
  locale = environment.locale;
  FeaturesRoutingEnum = FeaturesRoutingEnum;
  track = track;
  trackEvent = trackEvent;

  @Select(SessionState.currentAppId)
  currentAppId$: Observable<string>;

  @Select(SessionState.currentAppUniverse)
  currentAppUniverse$: Observable<string>;

  @Select(ServicesState.accountI18nConfig)
  accountI18nConfig$: Observable<CountryService>;

  readonly accountApiUrl: string;
  readonly authtApiUrl: string;
  readonly contactApiUrl: string;

  get isMobile$(): Observable<boolean> {
    return this.breakpointsService.isMobile$;
  }

  get isTabletPortrait$(): Observable<boolean> {
    return this.breakpointsService.isTabletPortrait$;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  constructor(
    @Inject(DOCUMENT) private readonly document: any,
    @Inject(PLATFORM_ID) private readonly platformId: any,
    @Inject(WINDOW) private readonly window: any,
    @Inject(ErrorHandler) errorHandlerService: BugsnagErrorHandler,
    private readonly cookieService: CookieService,
    private readonly router: Router,
    private readonly zone: NgZone,
    private readonly breakpointsService: BreakpointsService,
    private readonly renderer: Renderer2,
    private readonly route: ActivatedRoute,
    private readonly accountWebService: AccountWebservice,
    private readonly store: Store
  ) {
    this.accountApiUrl = isPlatformBrowser(platformId) ? '/_api/account-api' : 'http://account-api';
    this.authtApiUrl = isPlatformBrowser(platformId) ? '/_api/auth-api' : 'http://auth-api';
    this.contactApiUrl = isPlatformBrowser(platformId) ? '/_api/contact-api' : 'http://contact-api';

    this.currentAppId$
      .pipe(
        filter((currentAppId) => !!currentAppId),
        distinctUntilChanged(),
        pairwise()
      )
      .subscribe(([previous, current]) => {
        this.renderer.removeClass(this.document.documentElement, `theme-gdpr-${previous}`);
        this.renderer.addClass(this.document.documentElement, `theme-gdpr-${current}`);
      });

    if (!isPlatformBrowser(platformId)) {
      return;
    }

    /**
     * Disable hover on `touchstart` to cover browsers that do not support pointer events.
     * https://caniuse.com/#feat=pointer
     */
    fromEvent(this.window, 'touchstart', { passive: true })
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.hoverEnabled = false;
      });

    // Provide user data to Bugsnag
    this.store
      .select(SessionState.user)
      .pipe(filter((user) => !!user))
      .subscribe((user) => {
        errorHandlerService.bugsnagClient.setUser(undefined, user?.username ? user.username : undefined);
      });

    this.store
      .select(SessionState.isInitialized)
      .pipe(
        filter((isInitialized) => isInitialized),
        switchMap(() =>
          combineLatest([
            this.route.queryParamMap.pipe(
              debounceTime(0),
              map((queryParamMap) =>
                queryParamMap.get('app-id') ? queryParamMap.get('app-id') : environment.applicationId
              )
            ),
            this.store.select(SessionState.user),
          ])
        ),
        first(),
        map(([appId, user]) => {
          return trackingConfig(appId, isPlatformBrowser(this.platformId), user);
        }),
        switchMap((config) => combineLatest([this.store.selectOnce(SessionState.tokens), of(config)])),
        map(([tokens, config]) =>
          tokens
            ? {
                ...config,
                consentWidget: {
                  ...config.consentWidget,
                  auth: {
                    type: 'jwt',
                    token: tokens.token,
                  },
                },
              }
            : config
        ),
        switchMap((config) =>
          this.store.selectOnce(SessionState.identityCard).pipe(map((identityCard) => ({ config, identityCard })))
        ),
        map(({ config, identityCard }) =>
          identityCard
            ? {
                ...config,
                consentWidget: {
                  ...config.consentWidget,
                  fullName:
                    !!identityCard && !!identityCard.firstName && !!identityCard.lastName
                      ? `${identityCard.firstName} ${identityCard.lastName}`
                      : !!identityCard && !!identityCard.firstName
                        ? identityCard.firstName
                        : null,
                },
              }
            : config
        ),
        switchMap((config) => from(setupTracking(config)))
      )
      .subscribe(() => {
        this.subscriptions.push(
          this.store
            .select(SessionState.info)
            .pipe(
              map((token) => {
                const { 'user-id': userId } = { 'user-id': undefined, ...token };
                return userId;
              }),
              distinctUntilChanged(),
              tap((userId) => {
                window['wa']('set', 'wizbiiUserId', userId);
              }),
              switchMap(() => this.store.selectOnce(SessionState.tokens))
            )
            .subscribe((tokens) => {
              if (tokens) {
                window.WizbiiGdpr.setAuth({
                  type: 'jwt',
                  token: tokens.token,
                });
              }

              if (!this.trackingIsSetup) {
                const { href: url, pathname, search, hash } = window.location;
                const page = `${pathname}${search}${hash}`;
                track('pageview', { url, page });
                this.trackingIsSetup = true;
              }
            })
        );
      });

    this.showInterservicesHeader$ = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => !event.url.includes(FeaturesRoutingEnum.SignUp))
    );
  }

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(
          (event: NavigationEnd) =>
            event.url.includes(FeaturesRoutingEnum.SignUp) || event.url.includes(FeaturesRoutingEnum.SignIn)
        ),
        filter((result) => result)
      )
      .subscribe(() => {
        this.checkBanner();
      });

    this.accountI18nConfig$
      .pipe(
        filter((accountI18nConfig) => !!accountI18nConfig),
        untilDestroyed(this),
        distinctUntilChanged()
      )
      .subscribe((accountI18nConfig) => {
        this.profileUrl$.next(`${accountI18nConfig.serviceUri}/${FeaturesRoutingEnum.Profile}`);
        this.privacyUrl$.next(`${accountI18nConfig.serviceUri}/${FeaturesRoutingEnum.Privacy}`);
        this.securityUrl$.next(`${accountI18nConfig.serviceUri}/${FeaturesRoutingEnum.Security}`);
        this.notificationsUrl$.next(`${accountI18nConfig.serviceUri}/${FeaturesRoutingEnum.Notifications}`);
      });

    if (!isPlatformBrowser(this.platformId)) {
      return;
    }

    if (this.document.documentElement.lang === '') {
      this.document.documentElement.lang = environment.lang;
    }

    const routerState = this.store.selectSnapshot(RouterState)?.state;
    if (routerState?.url) {
      const params: WebVitalsParams = {
        params: routerState.params,
        path: routerState.url,
        applicationId: environment.applicationId,
        envFqdn: environment.domain,
      };
      const options: WebVitalsOptions = {
        dev: environment.platform === 'local',
        debug: environment.platform === 'local',
        browser: isPlatformBrowser(this.platformId),
      };
      sendWebVitals(params, options);
    }
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
        if (this.trackingIsSetup) {
          const { href: url, pathname, search, hash } = this.window.location;
          const page = `${pathname}${search}${hash}`;
          this.track('pageview', { url, page });
        }
      })
    );
  }

  onActivate(component: any): void {
    if (this.isHome) {
      this.marginTopFooter$.next('3.3125rem');
    }

    if (component instanceof SignUpComponent) {
      this.isSignup$.next(true);
    }

    if (!(component instanceof DashboardLayoutComponent)) {
      return;
    }

    component.containerElem$
      .pipe(
        filter((element) => !!element),
        first()
      )
      .subscribe((element: ElementRef) => {
        this.observer = new ResizeObserver((entries) => {
          this.zone.run(() => {
            this.marginTopFooter$.next(`${entries[0].contentRect.height / 16 + 2.8125}rem`);
          });
        });

        this.targetObserve = element.nativeElement;
        this.observer.observe(this.targetObserve);
      });
  }

  /**
   * Enable hover if "mouse" pointer event is detected; disable it otherwise.
   * https://developer.mozilla.org/en-US/docs/Web/Events/pointerenter
   */
  @HostListener('pointerenter', ['$event'])
  onPointerEnter(event: any): void {
    const evt: PointerEvent = event; // https://github.com/kulshekhar/ts-jest/issues/1035
    this.hoverEnabled = evt.pointerType === 'mouse';
  }

  @HostListener('mousedown')
  onMouseDown(): void {
    this.focusOutlineDisabled = true;
  }

  @HostListener('keydown.Tab')
  onTabKeyDown(): void {
    this.focusOutlineDisabled = false;
  }

  onTriggerMenu(open: boolean): void {
    this.showInterservicesHeader$ = of(!open);
  }

  updatePreferredLocale(locale: string): void {
    if (locale !== null && locale !== this.locale) {
      const cookieDomain = this.getCookieDomain();
      const expiryExists = this.cookieService.check(this.cookieExpiryName);
      const msIn390Days = 1000 * 3600 * 24 * 390;
      const expiry = expiryExists
        ? new Date(this.cookieService.get(this.cookieExpiryName))
        : new Date(Date.now() + msIn390Days);

      if (!expiryExists) {
        this.cookieService.set(
          this.cookieExpiryName,
          expiry.getTime().toString(),
          expiry,
          '/',
          cookieDomain,
          cookieDomain !== 'localhost',
          cookieDomain === 'localhost' ? 'Lax' : 'None'
        );
      }

      this.cookieService.set(
        this.cookieName,
        locale,
        expiry,
        '/',
        cookieDomain,
        cookieDomain !== 'localhost',
        cookieDomain === 'localhost' ? 'Lax' : 'None'
      );

      if (isPlatformBrowser(this.platformId)) {
        this.accountWebService
          .getServicesByCountry(locale as LocaleEnum)
          .pipe(map((country) => country.services.find((service) => service.id === 'account')))
          .subscribe((accountI18nConfig) => (this.window.location.href = accountI18nConfig.serviceUri));
      }
    }
  }

  private getCookieDomain(): string {
    const cookieSubDomain = ['', ...this.document.location.hostname.split('.').slice(-2)].join('.');
    return cookieSubDomain === '.localhost' ? 'localhost' : `.${environment.domain}`;
  }

  private checkBanner() {
    let timer = 0;
    const checkBanner = setInterval(() => {
      timer += 100;

      if (this.window.WizbiiGdpr?.isBannerVisible !== undefined) {
        clearInterval(checkBanner);
        this.bannerIsVisible$.next(true);
      } else if (timer >= 3000) {
        clearInterval(checkBanner);
      }
    }, 100);
  }

  ngOnDestroy(): void {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
    if (this.observer && this.targetObserve) {
      this.observer.unobserve(this.targetObserve);
    }
    this.marginTopFooter$.complete();
    this.isSignup$.complete();
    this.bannerIsVisible$.complete();
  }
}
