<section class="context-jobs-home">
  <ng-container *ngIf="(isMobileAndTabletPortrait$ | async) === false; else mobileContext">
    <div class="context-jobs-home__content">
      <h2 class="context-jobs-home__content__title" i18n>Bonjour et bienvenue sur WIZBII Jobs&nbsp;!</h2>
      <p class="context-jobs-home__content__subtitle" i18n>
        Trouvez dès à présent votre prochain stage, alternance ou nouvel emploi&nbsp;!
      </p>

      <div class="context-jobs-home__content__card">
        <mat-icon class="context-jobs-home__content__card__icon" svgIcon="briefcase" height="3.125em" width="3.125em" />
        <p i18n>Plus de&nbsp;<span class="highlighted">150&nbsp;000</span>&nbsp;jobs à pourvoir</p>
      </div>

      <div class="context-jobs-home__content__card">
        <mat-icon class="context-jobs-home__content__card__icon" svgIcon="building" height="3.125em" width="3.125em" />
        <p i18n>Des&nbsp;<span class="highlighted">centaines d'entreprises</span>&nbsp;qui recrutent chaque jour</p>
      </div>

      <div class="context-jobs-home__content__card context-jobs-home__last-card">
        <mat-icon class="context-jobs-home__content__card__icon" svgIcon="location" height="3.125em" width="3.125em" />
        <p i18n>
          Plus de&nbsp;<span class="highlighted">100 évènements</span>&nbsp;de recrutements dans la France entière
        </p>
      </div>
    </div>
  </ng-container>
</section>

<ng-template #mobileContext>
  <p class="context-jobs-home__pop-up" i18n>
    <span class="highlighted highlighted--green">Trouvez dès à présent</span> votre prochain stage, alternance ou nouvel
    emploi&nbsp;!&nbsp;💼
  </p>
</ng-template>
