import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Coupon } from '@models/drive/coupon';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  private readonly baseUrl: string;

  constructor(
    private readonly http: HttpClient,
    @Inject(PLATFORM_ID) platformId: any
  ) {
    if (isPlatformBrowser(platformId)) {
      this.baseUrl = `/_api/permii-api`;
    } else {
      this.baseUrl = `http://permii-api`;
    }
  }

  getCouponPublic(couponId: string): Observable<Coupon> {
    return this.http.get<Coupon>(`${this.baseUrl}/v1/coupon/${couponId}/public`);
  }
}
