import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { environment } from '@environment';

@Component({
  template: `<section class="context-money-home" [ngClass]="'context-money-home--' + lang"></section>`,
  styleUrls: ['./context-money-home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMoneyHomeComponent {
  lang = environment.lang;
}

@NgModule({
  imports: [CommonModule],
  declarations: [ContextMoneyHomeComponent],
  exports: [ContextMoneyHomeComponent],
})
export class ContextMoneyHomeModule {}
