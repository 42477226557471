import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@wizbii-utils/angular/core';

/**
 * Manages the scroll position for a browser window.
 */
@Injectable()
export class AppBrowserViewportScroller implements ViewportScroller {
  constructor(
    @Inject(DOCUMENT) private readonly document: any,
    @Inject(WINDOW) private readonly window: any
  ) {}

  private offset: () => [number, number] = () => [0, 0];

  /**
   * Configures the top offset used when scrolling to an anchor.
   * @param offset A position in screen coordinates (a tuple with x and y values)
   * or a function that returns the top offset position.
   *
   */
  setOffset(offset: [number, number] | (() => [number, number])): void {
    // tslint:disable-next-line:prefer-conditional-expression
    if (Array.isArray(offset)) {
      this.offset = () => offset;
    } else {
      this.offset = offset;
    }
  }

  /**
   * Retrieves the current scroll position.
   * @returns The position in screen coordinates.
   */
  getScrollPosition(): [number, number] {
    if (this.supportScrollRestoration()) {
      return [this.window.scrollX, this.window.scrollY];
    }

    return [0, 0];
  }

  /**
   * Sets the scroll position.
   * @param position The new position in screen coordinates.
   */
  scrollToPosition(position: [number, number]): void {
    if (this.supportScrollRestoration()) {
      this.window.scrollTo(position[0], position[1]);
    }
  }

  /**
   * Scrolls to an anchor element.
   * @param anchor The ID of the anchor element.
   */
  scrollToAnchor(anchor: string): void {
    if (this.supportScrollRestoration()) {
      const elSelectedById = this.document.getElementById(anchor);
      if (elSelectedById) {
        this.scrollToElement(elSelectedById);
        return;
      }
      const elSelectedByName = this.document.querySelector(`[name='${anchor}']`);
      if (elSelectedByName) {
        this.scrollToElement(elSelectedByName);
        return;
      }
    }
  }

  /**
   * Disables automatic scroll restoration provided by the browser.
   */
  setHistoryScrollRestoration(scrollRestoration: 'auto' | 'manual'): void {
    if (this.supportScrollRestoration()) {
      const history = this.window.history;
      if (history && history.scrollRestoration) {
        history.scrollRestoration = scrollRestoration;
      }
    }
  }

  private scrollToElement(el: any): void {
    const rect = el.getBoundingClientRect();
    // tslint:disable-next-line:restrict-plus-operands
    const left = rect.left + this.window.pageXOffset;
    // tslint:disable-next-line:restrict-plus-operands
    const top = rect.top + this.window.pageYOffset;
    const offset = this.offset();

    this.window.scrollTo(left - offset[0], top - offset[1]);
  }

  /**
   * We only support scroll restoration when we can get a hold of window.
   * This means that we do not support this behavior when running in a web worker.
   *
   * Lifting this restriction right now would require more changes in the dom adapter.
   * Since webworkers aren't widely used, we will lift it once RouterScroller is
   * battle-tested.
   */
  private supportScrollRestoration(): boolean {
    return !(!this.window || !this.window.scrollTo || !('scrollRestoration' in this.window.history));
  }
}
