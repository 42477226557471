<div class="logo">
  <a *ngIf="(hasCurrentApp$ | async) === true" [href]="(currentApp$ | async)?.serviceUri">
    <img
      *ngIf="(appIdIsNexity$ | async) === false"
      class="logo__img"
      [src]="(currentApp$ | async)?.logoUri"
      [alt]="(currentApp$ | async)?.name"
    />

    <div *ngIf="(currentApp$ | async)?.id === 'fibii' && (appIdIsNexity$ | async) === true" class="container">
      <img [src]="(currentApp$ | async)?.logoUri" [alt]="(currentApp$ | async)?.name" />
      <span class="logo__divider"></span>
      <img class="logo--nexity" src="assets/images/logos/nexity.svg" alt="Nexity" matSuffix />
    </div>
  </a>
  <a *ngIf="(hasCurrentApp$ | async) === false" routerLink="/">
    <img
      class="logo__img"
      src="https://storage.googleapis.com/wizbii/static-assets-v4/logos/SVG/RGB/WIZBII_Account.svg"
      alt="Logo WIZBII Account"
    />
  </a>
</div>
