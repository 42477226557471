import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { SignUpService } from '@features/sign-up/services/sign-up.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  exports: [CommonModule],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [SignUpService],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule?: SharedModule) {
    if (parentModule) {
      throw new Error('SharedModule is already loaded. Import it in the CoreModule only');
    }
  }
}
