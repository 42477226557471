<mat-nav-list class="navmenu">
  <a [routerLink]="['/']" (click)="trackEvent('Navigation', 'Click Logo')">
    <img
      class="navmenu__logo"
      [width]="160"
      src="https://storage.googleapis.com/wizbii/static-assets-v4/logos/SVG/RGB/WIZBII_Account.svg"
      alt="Logo WIZBII Account"
    />
  </a>

  <nav class="navmenu__links">
    <a
      class="navmenu__link"
      [routerLink]="['/', FeaturesRoutingEnum.Dashboard]"
      (click)="trackEvent('Navigation', 'Click Accueil')"
      routerLinkActive="navmenu__link--active"
    >
      <div class="link__text" i18n>Accueil</div>
    </a>

    <a
      class="navmenu__link"
      [routerLink]="['/', FeaturesRoutingEnum.Profile]"
      (click)="trackEvent('Navigation', 'Click Mon Profil')"
      routerLinkActive="navmenu__link--active"
    >
      <div class="link__text" i18n>Mon profil</div>
    </a>

    <a
      class="navmenu__link"
      [routerLink]="['/', FeaturesRoutingEnum.Privacy]"
      (click)="trackEvent('Navigation', 'Click Confidentialité')"
      routerLinkActive="navmenu__link--active"
    >
      <div class="link__text" i18n>Confidentialité</div>
    </a>

    <a
      class="navmenu__link"
      [routerLink]="['/', FeaturesRoutingEnum.Security]"
      (click)="trackEvent('Navigation', 'Click Sécurité')"
      routerLinkActive="navmenu__link--active"
    >
      <div class="link__text" i18n>Sécurité & Connexion</div>
    </a>

    <a
      class="navmenu__link"
      [routerLink]="['/', FeaturesRoutingEnum.Notifications]"
      (click)="trackEvent('Navigation', 'Click Notifications')"
      routerLinkActive="navmenu__link--active"
    >
      <div class="link__text" i18n>Notifications</div>
    </a>
  </nav>
</mat-nav-list>
