<div
  *ngIf="notifications?.length !== 0"
  class="notifications"
  [ngClass]="['notifications--' + (position$ | async)]"
  [@animateToasts]
>
  <div
    *ngFor="let notification of notifications; let i = index; trackBy: trackById"
    class="notification"
    [ngClass]="['notification--' + notification.style]"
    [@animateToasts]
  >
    <!-- eslint-disable @angular-eslint/template/no-inline-styles -->
    <div class="notification__bar" [style.animationDuration]="(notification.duration || defaultDuration) + 'ms'"></div>

    <mat-icon class="notification__close" (click)="removeNotification(i)" icon="exp-cross" height=".6em" width=".6em" />

    <div class="notification-message">
      <div *ngIf="notification.style" class="notification-message__picto">
        <mat-icon *ngIf="notification.style === NotifStatus.Success" svgIcon="exp-valid" width="1.5em" />
        <mat-icon *ngIf="notification.style === NotifStatus.Failed" svgIcon="exp-alert" width="1.5em" />
      </div>

      <div class="notification-message__content">
        <p [innerHTML]="notification.message"></p>
      </div>
    </div>

    <button *ngIf="notification.callbackConfig" class="btn-clean notification__button" type="button">
      {{ notification.callbackConfig.text }}
    </button>
  </div>
</div>
