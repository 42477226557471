import { Inject, Injectable, OnDestroy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/feature-routing-enum';
import { PossibleInputTypes, possibleInputs } from '@features/sign-up/possible-inputs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { SetConsent } from '@stores/consents/consents.actions';
import { ServicesState } from '@stores/services/services.state';
import { SessionState } from '@stores/session/session.state';
import { FormWebservice, RegisterFormStatutEnum } from '@webservices/form/form.webservice';
import { WINDOW } from '@wizbii-utils/angular/core';
import { ArticleTypes, ConsentInterface, CountryService } from '@wizbii/utils/models';
import { BehaviorSubject, Observable, combineLatest, from, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Injectable()
export class SignUpService implements OnDestroy {
  private _signupForm: UntypedFormGroup;

  get signupForm(): UntypedFormGroup {
    return this._signupForm;
  }

  @Select(SessionState.currentApp)
  currentApp$: Observable<CountryService>;

  @Select(ServicesState.accountI18nConfig)
  accountI18nConfig$: Observable<CountryService>;

  isInitialised$ = new BehaviorSubject<boolean>(false);
  hasAllInformations$ = new BehaviorSubject<boolean>(false);
  registerStatus$ = new BehaviorSubject<RegisterFormStatutEnum>(null);

  get hasCivility(): boolean {
    return this._signupForm?.controls ? Object.keys(this._signupForm.controls).includes('civility') : false;
  }

  get hasFirstName(): boolean {
    return this._signupForm?.controls ? Object.keys(this._signupForm.controls).includes('firstName') : false;
  }

  get hasLastName(): boolean {
    return this._signupForm?.controls ? Object.keys(this._signupForm.controls).includes('lastName') : false;
  }

  get hasDateBirthday(): boolean {
    return this._signupForm?.controls ? Object.keys(this._signupForm.controls).includes('dateBirthday') : false;
  }

  get hasLocation(): boolean {
    return this._signupForm?.controls ? Object.keys(this._signupForm.controls).includes('location') : false;
  }

  get hasMobile(): boolean {
    return this._signupForm?.controls ? Object.keys(this._signupForm.controls).includes('mobile') : false;
  }

  get hasUsername(): boolean {
    return this._signupForm?.controls ? Object.keys(this._signupForm.controls).includes('username') : false;
  }

  get hasPassword(): boolean {
    return this._signupForm?.controls ? Object.keys(this._signupForm.controls).includes('password') : false;
  }

  get hasCgu(): boolean {
    return this._signupForm?.controls ? Object.keys(this._signupForm.controls).includes('checkbox-cgu') : false;
  }

  get hasNexity(): boolean {
    return this._signupForm?.controls ? Object.keys(this._signupForm.controls).includes('checkbox-nexity') : false;
  }

  // tslint:disable-next-line: cognitive-complexity
  constructor(
    private readonly route: ActivatedRoute,
    private readonly formWebservice: FormWebservice,
    @Inject(WINDOW) private readonly window: any,
    private readonly store: Store
  ) {
    combineLatest([
      this.registerStatus$.pipe(
        filter((registerStatut) => registerStatut !== null),
        distinctUntilChanged()
      ),
      this.route.queryParamMap.pipe(
        map((queryParamMap) => queryParamMap.get('app-id')),
        map((appId) => (appId ? appId : environment.applicationId)),
        distinctUntilChanged()
      ),
      combineLatest([
        this.route.queryParamMap,
        this.accountI18nConfig$.pipe(
          filter((accountI18nConfig) => !!accountI18nConfig),
          distinctUntilChanged()
        ),
      ]).pipe(
        map(
          ([queryParamMap, accountI18nConfig]) =>
            queryParamMap.get('redirect') ?? `${accountI18nConfig.serviceUri}/${FeaturesRoutingEnum.Dashboard}`
        ),
        distinctUntilChanged()
      ),
    ])
      .pipe(
        switchMap(([registerStatut, appId, redirect]) => {
          return combineLatest([
            this.formWebservice.getForm(
              appId,
              registerStatut ? registerStatut : RegisterFormStatutEnum.newRegister,
              redirect
            ),
            of(registerStatut),
          ]);
        })
      )
      .subscribe(([form, registerStatut]) => {
        if (form) {
          this.hasAllInformations$.next(false);
          this._signupForm = new UntypedFormGroup(
            form.reduce((acc, inputForm) => {
              const input = possibleInputs[inputForm.name];
              const isCheckbox = input.type === PossibleInputTypes.Checkbox;
              const requiredValidator =
                inputForm.required && isCheckbox
                  ? Validators.requiredTrue
                  : inputForm.required
                    ? Validators.required
                    : null;
              return {
                ...acc,
                [inputForm.name]: new UntypedFormControl(input.defaultValue, [
                  ...input.validators,
                  ...(requiredValidator ? [requiredValidator] : []),
                ]),
              };
            }, {} as any)
          );

          this.initCgu();
          this.initNexity();
        } else if (registerStatut === RegisterFormStatutEnum.serviceActivation) {
          this.hasAllInformations$.next(true);
        }
        this.isInitialised$.next(true);
      });
  }

  private initCgu() {
    if (this.hasCgu) {
      combineLatest([
        this._signupForm.get('checkbox-cgu').valueChanges,
        this.currentApp$.pipe(map((currentApp) => (currentApp?.id ? currentApp.id : environment.applicationId))),
      ])
        .pipe(
          untilDestroyed(this),
          distinctUntilChanged(),
          switchMap(([consentCgu, appId]) =>
            from(
              this.window.WizbiiGdpr.saveAllConsents([
                { key: ArticleTypes.CGU, value: consentCgu, product: 'global' },
                { key: ArticleTypes.CPU, value: consentCgu, product: appId },
                { key: ArticleTypes.PRIVACY_POLICY, value: consentCgu, product: appId },
              ])
            )
          )
        )
        .subscribe(([consentCgu, consentCpu, consentPrivacy]) => {
          this.store.dispatch([new SetConsent(consentCgu), new SetConsent(consentCpu), new SetConsent(consentPrivacy)]);
        });
    }
  }

  private initNexity() {
    if (this.hasNexity) {
      combineLatest([
        this._signupForm.get('checkbox-nexity').valueChanges,
        this.currentApp$.pipe(map((currentApp) => (currentApp?.id ? currentApp.id : environment.applicationId))),
      ])
        .pipe(
          untilDestroyed(this),
          distinctUntilChanged(),
          switchMap(([consentNexity, appId]) =>
            from<Observable<ConsentInterface>>(
              this.window.WizbiiGdpr.saveConsent({ key: 'sharing_data_nexity', value: consentNexity }, appId)
            )
          )
        )
        .subscribe((consentSharingNexity) => {
          this.store.dispatch([new SetConsent(consentSharingNexity)]);
        });
    }
  }

  ngOnDestroy(): void {
    this.isInitialised$.complete();
  }
}
