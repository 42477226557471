import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-cnil-text',
  templateUrl: './cnil-text.component.html',
  styleUrls: ['./cnil-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CnilTextComponent {
  @Input() appId: string;
  @Input() extraContent: string;

  showCnilText = false;
}
