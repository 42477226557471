import { AnimationTriggerMetadata, animate, style, transition, trigger } from '@angular/animations';

export const AnimationConfig: Record<string, string> = {
  willChange: 'transform opacity',
  animateLong: '.4s cubic-bezier(.645, .045, .355, 1)',
  animateShort: '.2s cubic-bezier(.645, .045, .355, 1)',
};

export function slide(): AnimationTriggerMetadata {
  return trigger('slide', [
    transition(':enter', [
      style({ opacity: 0, transform: 'translateX(40%)', willChange: AnimationConfig.willChange }),
      animate(AnimationConfig.animateShort),
    ]),
    transition(':leave', [
      style({ willChange: AnimationConfig.willChange }),
      animate(AnimationConfig.animateShort, style({ opacity: 0, transform: 'translateX(40%)' })),
    ]),
  ]);
}

export function slideDown(): AnimationTriggerMetadata {
  return trigger('slideDown', [
    transition(':enter', [
      style({ opacity: 0, transform: 'translateY(-10%)', willChange: 'opacity transform' }),
      animate('.2s ease-in-out', style({ opacity: 1, transform: 'translateY(0)' })),
    ]),
    transition(':leave', [
      style({ opacity: 1, transform: 'translateY(0)', willChange: 'opacity transform' }),
      animate('.2s ease-in-out', style({ opacity: 0, transform: 'translateY(-10%)' })),
    ]),
  ]);
}
