import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BreakpointsService } from '@core/services/breakpoints/breakpoints.service';
import { environment } from '@environment';
import { Coupon } from '@models/drive/coupon';
import { OffersNames, Prices } from '@models/drive/payment';
import { Select } from '@ngxs/store';
import { SessionState } from '@stores/session/session.state';
import { PaymentService } from '@webservices/drive/payment.service';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap } from 'rxjs/operators';

@Component({
  templateUrl: './context-drive-influencer.component.html',
  styleUrls: ['./context-drive-influencer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextDriveInfluencerComponent implements OnInit {
  coupon$: Observable<Coupon>;
  couponOfferName$: Observable<string>;
  couponPrice$: Observable<number>;
  couponBasePrice$: Observable<number>;
  driveUrl = environment.urls.driveUrl;
  lang = environment.lang;

  @Select(SessionState.contextCoupon)
  couponStr$: Observable<string>;

  get isMobileAndTabletPortrait$(): Observable<boolean> {
    return this.breakpointsService.isMobileAndTabletPortrait$;
  }

  constructor(
    private readonly paymentWebservice: PaymentService,
    private readonly breakpointsService: BreakpointsService
  ) {}

  ngOnInit(): void {
    this.coupon$ = this.couponStr$.pipe(
      distinctUntilChanged(),
      switchMap((coupon) => this.paymentWebservice.getCouponPublic(coupon)),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.couponOfferName$ = this.coupon$.pipe(
      map((coupon) => {
        if (coupon.restrictedToArticles && coupon.restrictedToArticles.length === 1) {
          const itemSplit = coupon.restrictedToArticles[0].split('-');
          return OffersNames[itemSplit[0]][itemSplit[1]][itemSplit[2]];
        }

        return null;
      }),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.couponBasePrice$ = this.coupon$.pipe(
      map((coupon) => {
        if (coupon.restrictedToArticles && coupon.restrictedToArticles.length === 1) {
          const itemSplit = coupon.restrictedToArticles[0].split('-');
          return Prices[itemSplit[0]][itemSplit[1]][itemSplit[2]];
        }

        return null;
      }),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.couponPrice$ = combineLatest([this.coupon$, this.couponBasePrice$]).pipe(
      map(([coupon, couponBasePrice]) => {
        if (coupon.restrictedToArticles && coupon.restrictedToArticles.length === 1 && coupon.amountOff) {
          return couponBasePrice - coupon.amountOff;
        }

        if (coupon.restrictedToArticles && coupon.restrictedToArticles.length === 1 && coupon.percentOff) {
          return (couponBasePrice * (100 - coupon.percentOff)) / 100;
        }

        return null;
      }),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }
}

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [ContextDriveInfluencerComponent],
  exports: [ContextDriveInfluencerComponent],
})
export class ContextDriveInfluencerModule {}
