import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { BugsnagService } from '@wizbii-utils/angular/bugsnag';
import { loadScript } from '@wizbii/utils/tracking';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'wzb-trust-box-widget',
  template: `
    <div
      class="trustpilot-widget"
      [id]="trustBoxId()"
      data-locale="fr-FR"
      data-template-id="5419b637fa0340045cd0c936"
      data-businessunit-id="6057b7fc5c783500013272ea"
      data-style-height="20px"
      data-style-width="100%"
      data-theme="light"
      data-font-family="Montserrat"
      data-text-color="#000028"
    >
      <a href="https://fr.trustpilot.com/review/money.wizbii.com" target="_blank" rel="noopener">Trustpilot</a>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrustBoxWidgetComponent implements AfterViewInit {
  trustBoxId = input.required<string>();

  readonly #errorHandlerService = inject(BugsnagService);

  constructor() {
    if (!(window as any).Trustpilot) {
      loadScript('//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js').catch((error) => {
        this.#errorHandlerService.sendError(error, 'warning', { errorMessage: 'Unable to load TrustBox script' });
      });
    }
  }

  ngAfterViewInit() {
    const loadTrustpilot = setInterval(() => {
      if (!(window as any).Trustpilot) {
        return;
      }
      const trustBoxRef = document.getElementById(this.trustBoxId());
      (window as any).Trustpilot?.loadFromElement(trustBoxRef);
      clearInterval(loadTrustpilot);
    }, 0);
  }
}
