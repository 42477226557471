<div class="header">
  <div class="header__menu">
    <app-logo class="header__menu__logo" />
  </div>
</div>
<div class="layout" [class.layout--email]="(showSignupEmail$ | async) === true">
  <div class="layout__form">
    <router-outlet />
  </div>

  <section class="layout__context">
    <ng-template #contextComponent />
  </section>
</div>

<app-cnil-text [appId]="currentAppId$ | async" [extraContent]="extraContent$ | async" />
