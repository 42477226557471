import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { RedirectSafeUrlService } from '@core/services/redirect-safe-url/redirect-safe-url.service';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/feature-routing-enum';
import { LastConnectionService } from '@features/last-connection/last-connection.service';
import { Store } from '@ngxs/store';
import { SetProvider } from '@stores/session/session.actions';
import { SessionState } from '@stores/session/session.state';
import { AccountWebservice } from '@wizbii-utils/angular/webservices';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

@Injectable()
export class IsAlreadyConnectedGuard {
  constructor(
    private readonly router: Router,
    private readonly store: Store,
    private readonly lastConnectionService: LastConnectionService,
    private readonly redirectSafeUrlService: RedirectSafeUrlService,
    private readonly accountWebservice: AccountWebservice
  ) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.store.select(SessionState.isInitialized).pipe(
      filter((isInitialized) => isInitialized),
      take(1),
      switchMap(() => this.store.selectOnce(SessionState.tokens).pipe(map((tokens) => !!tokens))),
      switchMap((isLogged) => {
        const redirect = next.queryParamMap.get('redirect');
        const source = next.queryParamMap.get('source');
        const provider = next.queryParamMap.get('provider');

        if (provider) {
          this.store.dispatch(new SetProvider(provider));
        }

        if (isLogged && redirect) {
          return this.store.select(SessionState.userId).pipe(
            filter((userId): userId is string => !!userId),
            take(1),
            tap((userId: string) => this.lastConnectionService.persistLastConnection(userId)),
            switchMap((userId) => (source ? this.accountWebservice.setSource(userId, source) : of({}))),
            tap(() => {
              this.redirectSafeUrlService.safeOpen(redirect, environment.domainsByLocale[environment.locale], '_self');
            }),
            map(() => false)
          );
        }

        if (isLogged) {
          return this.store.select(SessionState.userId).pipe(
            filter((userId): userId is string => !!userId),
            take(1),
            tap((userId: string) => this.lastConnectionService.persistLastConnection(userId)),
            switchMap((userId) => (source ? this.accountWebservice.setSource(userId, source) : of({}))),
            map(() => this.router.parseUrl(FeaturesRoutingEnum.Dashboard))
          );
        }

        return of(true);
      })
    );
  }
}
