<section class="context-drive-suggestions">
  <ng-container *ngIf="(isMobileAndTabletPortrait$ | async) === false; else mobileContext">
    <p class="context-drive-suggestions__card context-drive-suggestions__card--1" i18n>
      <span class="context-drive-suggestions__card__colored">Accédez aux aides</span>
      auxquelles vous avez droit pour
      <span class="context-drive-suggestions__card__colored">financer votre permis</span>
    </p>
    <p class="context-drive-suggestions__card context-drive-suggestions__card--2" i18n>
      Répondez à quelques questions et découvrez si vous êtes
      <span class="context-drive-suggestions__card__colored">éligible aux aides locales et régionales</span>
    </p>
  </ng-container>
</section>

<ng-template #mobileContext>
  <p class="context-drive-suggestions__pop-up" i18n>
    Accédez aux aides auxquelles vous avez droit pour
    <span class="highlighted highlighted--blue">financer votre permis</span>&nbsp;🚗
  </p>
</ng-template>
