import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@environment';
import { Store } from '@ngxs/store';
import { SessionState } from '@stores/session/session.state';
import { deserializeJwt } from '@wizbii-utils/angular/jwt';
import { ConnectionInfo, IdentityCard, UserOverview, hideEmail, hideLastNameCharacters } from '@wizbii/utils/models';
import { CookieService } from 'ngx-cookie-service';
import { combineLatest } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class LastConnectionService {
  constructor(
    private readonly store: Store,
    private readonly cookieService: CookieService,
    @Inject(DOCUMENT) private readonly document: any
  ) {}

  persistLastConnection(userId: string): void {
    const lastConnectionCookieName = 'last-connection';
    const lastConnectionCookieExpiryName = 'last-connection-expiry';
    const token = JSON.parse(this.cookieService.get('wizbii_tokens')).token;
    const cookieDomain = this.getCookieDomain();
    const expiryExists = this.cookieService.check(lastConnectionCookieExpiryName);
    const msIn390Days = 1000 * 3600 * 24 * 390;
    const expiry = expiryExists
      ? new Date(this.cookieService.get(lastConnectionCookieExpiryName))
      : new Date(Date.now() + msIn390Days);

    combineLatest([
      this.store.select(SessionState.identityCard).pipe(
        filter((identityCard): identityCard is IdentityCard => !!identityCard),
        take(1)
      ),
      this.store.select(SessionState.user).pipe(
        filter((user): user is UserOverview => !!user),
        take(1)
      ),
      this.store.select(SessionState.provider).pipe(
        filter((provider): provider is string => !!provider),
        take(1)
      ),
    ]).subscribe({
      next: ([identityCard, user, provider]: [IdentityCard, UserOverview, string]) => {
        const lastConnection: ConnectionInfo = {
          provider,
          identifier: hideEmail(user.username),
          name: hideLastNameCharacters(`${identityCard.firstName} ${identityCard.lastName}`),
          uniqUserId: undefined,
          userId: userId ? userId : deserializeJwt(token)['user-id'],
          date: new Date(),
        };

        if (!expiryExists) {
          this.cookieService.set(
            lastConnectionCookieExpiryName,
            expiry.getTime().toString(),
            expiry,
            '/',
            cookieDomain,
            cookieDomain !== 'localhost',
            cookieDomain === 'localhost' ? 'Lax' : 'None'
          );
        }

        this.cookieService.set(
          lastConnectionCookieName,
          JSON.stringify(lastConnection),
          expiry,
          '/',
          cookieDomain,
          cookieDomain !== 'localhost',
          cookieDomain === 'localhost' ? 'Lax' : 'None'
        );
      },
    });
  }

  private getCookieDomain(): string {
    const cookieSubDomain = ['', ...this.document.location.hostname.split('.').slice(-2)].join('.');
    return cookieSubDomain === '.localhost' ? 'localhost' : `.${environment.domain}`;
  }
}
