import { HttpErrorResponse } from '@angular/common/http';
import { JwtTokens } from '@wizbii-utils/angular/jwt';
import { CountryService, IdentityCard, UserOverview } from '@wizbii/utils/models';

export abstract class SessionFailed {
  static readonly type;
  constructor(public error: HttpErrorResponse | Error) {}
}

export class Init {
  static readonly type = '[Session] Init';
  constructor(
    public tokens?: JwtTokens,
    public mustGetAll = true
  ) {}
}

export class AuthSuccess {
  static readonly type = '[Session] Auth Success';
  constructor(public tokens: JwtTokens) {}
}

export class AuthFailed {
  static readonly type = '[Session] Auth Failed';
}

export class RefreshTokensStore {
  static readonly type = '[Session] Refresh Tokens Store';
  constructor(public tokens: JwtTokens) {}
}

export class Logout {
  static readonly type = '[Session] Logout';
}

export class SetTokens {
  static readonly type = '[Session] Set Tokens';
  constructor(public tokens: JwtTokens) {}
}

export class SetIdentityCard {
  static readonly type = '[Session] SetIdentityCard';
  constructor(public identityCard: IdentityCard) {}
}

export class SetUser {
  static readonly type = '[Session] SetUser';
  constructor(public user: UserOverview) {}
}

export class SetCurrentApp {
  static readonly type = '[Session] SetCurrentApp';
  constructor(public currentApp: CountryService) {}
}

export class UpdateAuthenticationTypes {
  static readonly type = '[Session] UpdateAuthenticationTypes';
  constructor(
    public authenticationType: string,
    public add: boolean
  ) {}
}

export class SetContext {
  static readonly type = '[Session] Set Context';
  constructor(
    public payload?: {
      name?: string;
      appId?: string;
      jobId?: string;
      coupon?: string;
    }
  ) {}
}

export class SetProvider {
  static readonly type = '[Session] SetProvider';
  constructor(public provider: string) {}
}
