/* eslint-disable sonarjs/no-identical-functions */

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RedirectSafeUrlService } from '@core/services/redirect-safe-url/redirect-safe-url.service';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/feature-routing-enum';
import { LastConnectionService } from '@features/last-connection/last-connection.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Action, NgxsOnInit, Select, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { iif, insertItem, patch, removeItem } from '@ngxs/store/operators';
import { SetConsents } from '@stores/consents/consents.actions';
import { ServicesState } from '@stores/services/services.state';
import {
  AuthFailed,
  AuthSuccess,
  Init,
  Logout,
  RefreshTokensStore,
  SetContext,
  SetCurrentApp,
  SetIdentityCard,
  SetProvider,
  SetTokens,
  SetUser,
  UpdateAuthenticationTypes,
} from '@stores/session/session.actions';
import { WINDOW } from '@wizbii-utils/angular/core';
import { JwtTokens, deserializeJwt } from '@wizbii-utils/angular/jwt';
import {
  AccountWebservice,
  AuthenticationWebservice,
  ConsentWebservice,
  deleteWizbiiCookieTokens,
} from '@wizbii-utils/angular/webservices';
import { DataStorageService } from '@wizbii/angular-utilities';
import { CountryService, IdentityCard, UserOverview } from '@wizbii/utils/models';
import { CookieService } from 'ngx-cookie-service';
import qs from 'qs';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';

export enum SessionStatusEnum {
  Logged = 'LOGGED',
  NotLogged = 'NOT LOGGED',
}

const SessionStateToken = new StateToken<SessionStateModel>('session');

export class SessionStateModel {
  tokens: JwtTokens;
  status: SessionStatusEnum;
  user: UserOverview;
  authenticationTypes: string[];
  identityCard: IdentityCard;
  currentApp: CountryService;
  provider: string;
  context: {
    name: string;
    appId: string;
    jobId?: string;
    coupon?: string;
  };
}

export enum SessionErrorMessage {
  badCredential = 'BadCredentialsException',
  emailAlreadyUsed = 'EmailAlreadyUsedException',
}

const defaultState: SessionStateModel = {
  tokens: null,
  status: null,
  user: null,
  authenticationTypes: [],
  identityCard: null,
  currentApp: null,
  provider: null,
  context: {
    name: 'default',
    appId: environment.applicationId,
    jobId: null,
    coupon: null,
  },
};

@UntilDestroy()
@State<SessionStateModel>({
  name: SessionStateToken,
  defaults: defaultState,
})
@Injectable()
export class SessionState implements NgxsOnInit {
  static readonly TOKEN_KEY = 'wizbii_tokens';
  static readonly EXPIRY_KEY = 'wizbii_tokens_expiry';

  @Selector([SessionStateToken])
  static tokens(state: SessionStateModel) {
    return state.tokens;
  }

  @Selector([SessionStateToken])
  static provider(state: SessionStateModel) {
    return state.provider;
  }

  @Selector([SessionStateToken])
  static hasTokens(state: SessionStateModel) {
    return !!state.tokens;
  }

  @Selector([SessionStateToken])
  static currentContext(state: SessionStateModel) {
    return state.context.name;
  }

  @Selector([SessionStateToken])
  static contextJobId(state: SessionStateModel) {
    return state.context.jobId;
  }

  @Selector([SessionStateToken])
  static contextCoupon(state: SessionStateModel) {
    return state.context.coupon;
  }

  @Selector([SessionStateToken])
  static currentAppId(state: SessionStateModel) {
    return state.context.appId;
  }

  @Selector([SessionStateToken])
  static appIdIsNexity(state: SessionStateModel) {
    return state.context.appId.includes('nexity');
  }

  @Selector([SessionStateToken])
  static info(state: SessionStateModel): { 'external-services-package-id': string; 'user-id': string } {
    return state && state.tokens && state.tokens.token ? JSON.parse(atob(state.tokens.token.split('.')[1])) : undefined;
  }

  @Selector([SessionStateToken])
  static user(state: SessionStateModel) {
    return state.user;
  }

  @Selector([SessionStateToken])
  static userActivatedServices(state: SessionStateModel) {
    return state.user.activatedServices;
  }

  @Selector([SessionStateToken])
  static userId(state: SessionStateModel) {
    return state && state.tokens && state.tokens.token ? deserializeJwt(state.tokens.token)['user-id'] : undefined;
  }

  @Selector([SessionStateToken])
  static status(state: SessionStateModel) {
    return state.status;
  }

  @Selector([SessionStateToken])
  static isInitialized(state: SessionStateModel) {
    return state.status !== null;
  }

  @Selector([SessionState])
  static isLogged(state: SessionStateModel) {
    return state.status === SessionStatusEnum.Logged;
  }

  @Selector([SessionStateToken])
  static isNotLogged(state: SessionStateModel) {
    return state.status === SessionStatusEnum.NotLogged;
  }

  @Selector([SessionStateToken])
  static identityCard(state: SessionStateModel) {
    return state.identityCard;
  }

  @Selector([SessionState.user])
  static userDateCreated(user: UserOverview) {
    return user ? user.dateCreated : null;
  }

  @Selector([SessionStateToken])
  static currentApp(state: SessionStateModel) {
    return state.currentApp;
  }

  @Selector([SessionStateToken])
  static currentAppUniverse(state: SessionStateModel) {
    return state.currentApp?.universe;
  }

  @Selector([SessionStateToken])
  static hasCurrentApp(state: SessionStateModel) {
    return !!state.currentApp;
  }

  @Selector([SessionStateToken])
  static authenticationTypes(state: SessionStateModel) {
    return state.authenticationTypes;
  }
  @Select(ServicesState.accountI18nConfig)
  accountI18nConfig$: Observable<CountryService>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly cookieService: CookieService,
    private readonly lastConnectionService: LastConnectionService,
    private readonly accountWebservice: AccountWebservice,
    private readonly authWebservice: AuthenticationWebservice,
    private readonly consentWebservice: ConsentWebservice,
    private readonly dataStorage: DataStorageService,
    private readonly store: Store,
    private readonly redirectSafeUrlService: RedirectSafeUrlService,
    @Inject(DOCUMENT) private readonly document: any,
    @Inject(WINDOW) private readonly window: any
  ) {}

  ngxsOnInit() {
    this.store.selectOnce(SessionState.isInitialized).subscribe((isInitialized) => {
      if (!isInitialized && !this.window.location.pathname.includes('logout')) {
        this.store.dispatch(new Init());
      } else if (!isInitialized && this.window.location.pathname.includes('logout')) {
        this.store.dispatch(new Init(undefined, false));
      }
    });

    this.route.queryParamMap
      .pipe(
        map((queryParamMap) => queryParamMap.get('app-id')),
        filter((appId) => !!appId),
        take(1)
      )
      .subscribe((appId) => {
        this.store.dispatch(new SetContext({ appId }));
      });

    this.route.queryParamMap
      .pipe(
        map((queryParamMap) => queryParamMap.get('context')),
        filter((context) => !!context),
        take(1)
      )
      .subscribe((context) => {
        this.store.dispatch(new SetContext({ name: context }));
      });

    this.route.queryParamMap
      .pipe(
        map((queryParamMap) => queryParamMap.get('provider')),
        filter((provider) => !!provider),
        untilDestroyed(this)
      )
      .subscribe((provider) => {
        this.store.dispatch(new SetProvider(provider));
      });

    this.route.queryParamMap
      .pipe(
        map((queryParamMap) => queryParamMap.get('job-id')),
        filter((jobId) => !!jobId),
        take(1)
      )
      .subscribe((jobId) => {
        this.store.dispatch(new SetContext({ jobId }));
      });

    this.route.queryParamMap
      .pipe(
        map((queryParamMap) => queryParamMap.get('coupon')),
        filter((coupon) => !!coupon),
        map((coupon) => (coupon || '').trim().toUpperCase()),
        take(1)
      )
      .subscribe((coupon) => {
        this.store.dispatch(new SetContext({ coupon }));
      });

    this.route.queryParamMap
      .pipe(
        map((queryParamMap) => queryParamMap.get('app-id')),
        filter((appId) => !!appId),
        switchMap((appId) => this.store.select(ServicesState.getService(appId))),
        filter((service) => !!service),
        take(1)
      )
      .subscribe((service) => {
        this.store.dispatch(new SetCurrentApp(service));
      });
  }

  @Action(Init)
  init(ctx: StateContext<SessionStateModel>, { tokens, mustGetAll }: Init) {
    const realTokens = tokens ? tokens : this.readTokens();
    const appId = qs.parse(this.window.location.search.split('?')[1])['app-id'] as string;
    const execAppId = appId ? appId : environment.applicationId;

    if (realTokens) {
      ctx.patchState({ tokens: realTokens });

      const userId = deserializeJwt(realTokens.token)['user-id'];

      if (mustGetAll) {
        return this.authWebservice.getUserOverview(userId).pipe(
          tap((user) => ctx.patchState({ user, authenticationTypes: user.authenticationTypes })),
          switchMap(() => this.accountWebservice.getIdentityCard(userId)),
          tap((identityCard) => ctx.patchState({ identityCard: identityCard as unknown as IdentityCard })),
          switchMap(() => this.consentWebservice.getAll(userId, realTokens.token, execAppId)),
          tap((consents) => {
            if (consents) {
              return ctx.dispatch(new SetConsents(consents));
            }
            return null;
          }),
          tap(() => this.lastConnectionService.persistLastConnection(userId)),
          tap(() => ctx.patchState({ status: SessionStatusEnum.Logged }))
        );
      }

      this.lastConnectionService.persistLastConnection(userId);
      return ctx.patchState({ status: SessionStatusEnum.Logged });
    }

    const uuid = this.cookieService.get('uniqUserId');

    return this.consentWebservice.getAll(uuid, realTokens?.token, execAppId).pipe(
      tap((consents) => {
        if (consents) {
          return ctx.dispatch(new SetConsents(consents));
        }
        return null;
      }),
      tap(() => ctx.patchState({ status: SessionStatusEnum.NotLogged }))
    );
  }

  @Action(RefreshTokensStore)
  refreshTokensStore(ctx: StateContext<SessionStateModel>, { tokens }: RefreshTokensStore) {
    ctx.patchState({ tokens });
  }

  @Action(AuthSuccess)
  authSuccess(ctx: StateContext<SessionStateModel>, { tokens }: AuthSuccess): Observable<void> {
    this.writeTokens(tokens);

    ctx.patchState({
      status: SessionStatusEnum.Logged,
      tokens,
    });

    return ctx.dispatch(new Init(tokens, false));
  }

  @Action(AuthFailed)
  authFailed(ctx: StateContext<SessionStateModel>): void {
    this.forgetTokens();

    ctx.patchState({
      user: null,
      tokens: null,
      status: SessionStatusEnum.NotLogged,
    });
  }

  @Action(SetTokens)
  setTokens(ctx: StateContext<SessionStateModel>, { tokens }: SetTokens) {
    this.writeTokens(tokens);

    ctx.patchState({
      tokens,
    });
  }

  @Action(Logout)
  logout(ctx: StateContext<SessionStateModel>) {
    combineLatest([
      this.route.queryParamMap,
      this.accountI18nConfig$.pipe(
        filter((accountConfig) => !!accountConfig),
        distinctUntilChanged()
      ),
    ])
      .pipe(
        map(
          ([queryParamMap, accountI18nConfig]) =>
            queryParamMap.get('redirect') ?? `${accountI18nConfig.serviceUri}/${FeaturesRoutingEnum.Dashboard}`
        ),
        distinctUntilChanged(),
        shareReplay({ bufferSize: 1, refCount: true })
      )
      .subscribe((redirect) => {
        this.dataStorage.remove('lost-password-email');
        this.forgetTokens();
        ctx.setState(defaultState);
        this.redirectSafeUrlService.safeOpen(redirect, environment.domainsByLocale[environment.locale], '_self');
      });
  }

  @Action(SetIdentityCard)
  setProfile(ctx: StateContext<SessionStateModel>, { identityCard }: SetIdentityCard) {
    ctx.patchState({ identityCard });
  }

  @Action(SetUser)
  setUser(ctx: StateContext<SessionStateModel>, { user }: SetUser) {
    ctx.patchState({ user });
  }

  @Action(SetCurrentApp)
  setCurrentApp(ctx: StateContext<SessionStateModel>, { currentApp }: SetCurrentApp) {
    ctx.patchState({ currentApp: currentApp ? currentApp : null });
  }

  @Action(SetProvider)
  setProvider(ctx: StateContext<SessionStateModel>, { provider }: SetProvider) {
    ctx.patchState({ provider });
  }

  @Action(SetContext)
  setContext(ctx: StateContext<SessionStateModel>, { payload: { name, appId, jobId, coupon } }: SetContext) {
    const currentContext = ctx.getState().context;

    ctx.setState(
      patch<SessionStateModel>({
        context: patch({
          name: iif(!!name, name, currentContext.name),
          appId: iif(!!appId, appId, currentContext.appId),
          jobId: iif(!!jobId, jobId, currentContext.jobId),
          coupon: iif(!!coupon, coupon, currentContext.coupon),
        }),
      })
    );
  }

  @Action(UpdateAuthenticationTypes)
  updateAuthenticationTypes(
    ctx: StateContext<SessionStateModel>,
    { authenticationType, add }: UpdateAuthenticationTypes
  ) {
    ctx.setState(
      patch({
        authenticationTypes: add
          ? insertItem(authenticationType)
          : removeItem((authType) => authType === authenticationType),
      })
    );
  }

  private readTokens(): JwtTokens | null {
    const rawTokens = JSON.parse(this.cookieService.get(SessionState.TOKEN_KEY) || 'null');
    return rawTokens ? rawTokens : null;
  }

  private writeTokens(tokens: JwtTokens) {
    const cookieDomain = this.getCookieDomain();
    const expiryExists = this.cookieService.check(SessionState.EXPIRY_KEY);
    const msIn390Days = 1000 * 3600 * 24 * 390;

    function ensureTimestampHasMilliseconds(timestamp: string): number {
      return timestamp.length < 13 ? parseInt(timestamp, 10) * 1000 : parseInt(timestamp, 10);
    }

    const expiry = expiryExists
      ? new Date(ensureTimestampHasMilliseconds(this.cookieService.get(SessionState.EXPIRY_KEY)))
      : new Date(Date.now() + msIn390Days);

    if (!expiryExists) {
      this.cookieService.set(
        SessionState.EXPIRY_KEY,
        expiry.getTime().toString(),
        expiry,
        '/',
        cookieDomain,
        cookieDomain !== 'localhost',
        cookieDomain === 'localhost' ? 'Lax' : 'None'
      );
    }

    this.cookieService.set(
      SessionState.TOKEN_KEY,
      JSON.stringify(tokens),
      expiry,
      '/',
      cookieDomain,
      cookieDomain !== 'localhost',
      cookieDomain === 'localhost' ? 'Lax' : 'None'
    );
  }

  private forgetTokens() {
    deleteWizbiiCookieTokens(this.cookieService as any, {
      cookieDomains: environment.domainsByLocale,
      locale: environment.locale,
      platform: environment.platform,
    });
  }

  private getCookieDomain(): string {
    const cookieSubDomain = ['', ...this.document.location.hostname.split('.').slice(-2)].join('.');
    return cookieSubDomain === '.localhost' ? 'localhost' : `.${environment.domain}`;
  }
}
