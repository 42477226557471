import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { BreakpointsService } from '@core/services/breakpoints/breakpoints.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-context-money-nexity',
  templateUrl: './context-money-nexity.component.html',
  styleUrls: ['./context-money-nexity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMoneyNexityComponent {
  get isMobileAndTabletPortrait$(): Observable<boolean> {
    return this.breakpointsService.isMobileAndTabletPortrait$;
  }

  constructor(private readonly breakpointsService: BreakpointsService) {}
}

@NgModule({
  imports: [CommonModule],
  declarations: [ContextMoneyNexityComponent],
  exports: [ContextMoneyNexityComponent],
})
export class ContextMoneyNexityModule {}
