<section class="context-account">
  <ng-container *ngIf="(isMobileAndTabletPortrait$ | async) === false; else mobileContext">
    <div class="context-account__block-text context-account__block-text__top">
      <p class="context-account__text" i18n>
        Découvrez <span class="highlighted">l’univers WIZBII !</span> Emploi, financement, mobilité...
      </p>
    </div>

    <div class="context-account__block-text context-account__block-text__bottom">
      <p class="context-account__text" i18n>
        Nous transformons le passage à la vie active en
        <span class="highlighted">un moment optimiste et stimulant</span>
      </p>
    </div>

    <img class="context-account__background-image" src="assets/images/context-account/woman.png" alt="WIZBII" />
  </ng-container>
</section>

<ng-template #mobileContext>
  <p class="context-account__pop-up" i18n>
    Découvrez <span class="highlighted">l&apos;univers WIZBII&nbsp;!</span> Emploi, financement, mobilité...
  </p>
</ng-template>
