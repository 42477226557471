import { WizValidators } from '@commons/validators';

export enum PossibleInputTypes {
  Text = 'text',
  Date = 'date',
  Location = 'location',
  Password = 'password',
  Checkbox = 'checkbox',
  RadioButton = 'radioButton',
}

// tslint:disable: max-union-size
export interface FormInput {
  defaultValue: any;
  type: PossibleInputTypes;
  autocomplete?: string;
  validators: any[];
}

export const possibleInputs: Record<string, FormInput> = {
  civility: {
    defaultValue: '',
    type: PossibleInputTypes.RadioButton,
    validators: [],
  },
  firstName: {
    defaultValue: '',
    type: PossibleInputTypes.Text,
    autocomplete: 'given-name',
    validators: [],
  },
  lastName: {
    defaultValue: '',
    type: PossibleInputTypes.Text,
    autocomplete: 'family-name',
    validators: [],
  },
  dateBirthday: {
    defaultValue: '',
    type: PossibleInputTypes.Date,
    validators: [],
  },
  location: {
    defaultValue: '',
    type: PossibleInputTypes.Location,
    validators: [],
  },
  mobile: {
    defaultValue: '',
    type: PossibleInputTypes.Text,
    autocomplete: 'tel-local',
    validators: [],
  },
  username: {
    defaultValue: '',
    type: PossibleInputTypes.Text,
    autocomplete: 'username email',
    validators: [WizValidators.email],
  },
  password: {
    defaultValue: '',
    type: PossibleInputTypes.Password,
    autocomplete: 'new-password',
    validators: [],
  },
  ['checkbox-cgu']: {
    defaultValue: false,
    type: PossibleInputTypes.Checkbox,
    validators: [],
  },
  ['checkbox-nexity']: {
    defaultValue: false,
    type: PossibleInputTypes.Checkbox,
    validators: [],
  },
};
