<section class="context-drive-home">
  <ng-container *ngIf="(isMobileAndTabletPortrait$ | async) === false; else mobileContext">
    <div class="context-drive-home__card">
      <h2 class="context-drive-home__title" i18n>
        <span class="context-drive-home__title--blue">{{ (sponsorProfile$ | async)?.firstName }}</span> vous invite à
        réviser votre code de la route et vous fait bénéficier de -25% sur la formule d'entraînement illimité&nbsp;!*
        <br />
        Inscrivez-vous pour profiter de cette offre et commencez une série sans plus attendre. (offre valable 1 mois)
      </h2>

      <ul class="context-drive-home__list-items">
        <li class="context-drive-home__list-items__item">
          <mat-icon class="context-drive-home__list-items__icon" svg-icon="green-check" width="1.125em" />
          <ng-container i18n="@@context.drive.formation">Formation complète</ng-container>
        </li>
        <li class="context-drive-home__list-items__item">
          <mat-icon class="context-drive-home__list-items__icon" svg-icon="green-check" width="1.125em" />
          <ng-container i18n="@@context.drive.lesson">Cours thématiques</ng-container>
        </li>
        <li class="context-drive-home__list-items__item">
          <mat-icon class="context-drive-home__list-items__icon" svg-icon="green-check" width="1.125em" />
          <ng-container i18n="@@context.drive.license">Mode examen blanc</ng-container>
        </li>
        <li class="context-drive-home__list-items__item">
          <mat-icon class="context-drive-home__list-items__icon" svg-icon="green-check" width="1.125em" />
          <ng-container i18n="@@context.drive.progress">Suivi de progression</ng-container>
        </li>
      </ul>
    </div>
    <img
      class="context-drive-home__icon"
      src="assets/images/context-drive-home/computer.svg"
      alt="WIZBII drive"
      matSuffix
    />
  </ng-container>
</section>

<ng-template #mobileContext>
  <p class="context-drive-home__pop-up" i18n>
    <span class="highlighted highlighted--blue">Inscrivez-vous</span> et testez une série de code de la route
    <span class="highlighted highlighted--blue">gratuitement&nbsp;!</span>&nbsp;🚗
  </p>
</ng-template>
