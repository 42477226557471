import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store as NgrxStore } from '@ngrx/store';
import { initMoneySignupWordingABTestCampaign } from '@stores/abtest/abtest-ngrx.action';

export const initMoneySignupWordingAbTestGuard: CanActivateFn = (route) => {
  const currentAppId = route.queryParamMap.get('app-id');

  if (['fibii', 'fibii-home', 'fibii-nexity', 'fibii_nexity', 'money-intl'].includes(currentAppId)) {
    inject(NgrxStore).dispatch(initMoneySignupWordingABTestCampaign());
  }

  return true;
};
