<section class="context-money-collectivity">
  <div *ngIf="(isMobileAndTabletPortrait$ | async) === false; else mobileContext">
    <img class="ellipse" src="assets/images/collectivity-ellipse.svg" alt="" />
    <img class="hero" src="assets/images/context-money-collectivity/hero.svg" alt="" />
    <div class="card--1">
      <p><b class="card--1__name" i18n>Amélie</b></p>
      <p class="card--1__situation" i18n>21 ans Étudiante à ENSE3 Grenoble</p>
      <p class="card--1__suggestions" i18n>
        CROUS&nbsp;:&nbsp;209&nbsp;€&nbsp;/&nbsp;MOIS&nbsp;&nbsp;&nbsp;&nbsp;APL&nbsp;:&nbsp;97&nbsp;€&nbsp;/&nbsp;MOIS
      </p>
      <p class="card--1__total" i18n>
        <span>Total&nbsp;:&nbsp;3&nbsp;673&nbsp;€&nbsp;/&nbsp;an</span>&nbsp;pour l&apos;aider à financer ses études
      </p>
    </div>
    <p class="card--2" i18n>Calculez gratuitement vos aides financières&nbsp;!&nbsp;💴&nbsp;💰</p>
  </div>
</section>

<ng-template #mobileContext>
  <p class="context-money-collectivity__pop-up" i18n>
    Calculez gratuitement vos aides financières&nbsp;!&nbsp;💴&nbsp;💰
  </p>
</ng-template>
