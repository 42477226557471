<section class="context-drive-home">
  <ng-container *ngIf="(isMobileAndTabletPortrait$ | async) === false; else mobileContext">
    <img class="ellipse" src="assets/images/collectivity-ellipse.svg" alt="" />
    <img class="hero" src="assets/images/context-drive-collectivity/hero.svg" alt="" />
  </ng-container>
</section>

<ng-template #mobileContext>
  <p class="context-drive-home__pop-up" i18n>
    <span class="highlighted highlighted--blue">Inscrivez-vous</span> et testez une série de code de la route
    <span class="highlighted highlighted--blue">gratuitement&nbsp;!</span>&nbsp;🚗
  </p>
</ng-template>
