import { CountryService } from '@wizbii/utils/models';

export class SetServices {
  static readonly type = '[Services] Set';
  constructor(public services: CountryService[]) {}
}

export class SetAccountI18nConfig {
  static readonly type = '[AccountI18nConfig] Set';
  constructor(public accountI18nConfig: CountryService) {}
}

export class SetLegaleI18nConfig {
  static readonly type = '[LegaleI18nConfig] Set';
  constructor(public legaleI18nConfig: CountryService) {}
}
