import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { LocaleEnum } from '@models/commons/locales.enum';
import { Store } from '@ngxs/store';
import { Logout, RefreshTokensStore, SetTokens } from '@stores/session/session.actions';
import { SessionState } from '@stores/session/session.state';
import { JwtServiceInterface, JwtTokens } from '@wizbii-utils/angular/jwt';
import { AuthenticationWebservice } from '@wizbii-utils/angular/webservices';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class JwtService implements JwtServiceInterface {
  blacklistRoutes: (string | RegExp)[];

  constructor(
    private readonly store: Store,
    private readonly authService: AuthenticationWebservice,
    @Inject(PLATFORM_ID) platformId: any
  ) {
    const urlAuthUser = '((/v1/user).*(/email|password|overview|authentication))';
    const urlAuthAssociate = '((/associate).*(/apple|google|facebook))';

    const authApiUrl = isPlatformBrowser(platformId) ? '/_api/auth-api' : 'http://auth-api';
    const accountApiUrl = isPlatformBrowser(platformId) ? '/_api/account-api' : 'http://account-api';

    this.blacklistRoutes = [
      'https://storage.googleapis.com',
      `${accountApiUrl}/v1/services/${LocaleEnum.fr_FR}`,
      `${accountApiUrl}/v1/services/${LocaleEnum.en_GB}`,
      `${accountApiUrl}/v1/login`,
      `${accountApiUrl}/v1/login/process`,
      `${accountApiUrl}/v1/logout`,
      `${accountApiUrl}/v1/logout/process`,
      new RegExp(`${authApiUrl}(?!(${urlAuthUser}|${urlAuthAssociate}))`, 'i'),
    ];
  }

  getTokens(): Observable<JwtTokens> {
    return this.store.selectOnce(SessionState.tokens);
  }

  logout(): void {
    this.store.dispatch(new Logout());
  }

  refreshToken(tokens: JwtTokens): Observable<JwtTokens> {
    return this.authService
      .refreshToken(tokens)
      .pipe(
        switchMap((newTokens) => this.store.dispatch(new RefreshTokensStore(newTokens)).pipe(map(() => newTokens)))
      );
  }

  saveLocalTokens(jwtTokens: JwtTokens): void {
    this.store.dispatch(new SetTokens(jwtTokens));
  }
}
