import { Config } from '@bugsnag/core';
import { environment } from '@environment';

export const bugsnagConfig: Config = {
  apiKey: environment.bugsnagId,
  appVersion: environment.version,
  autoTrackSessions: false,
  releaseStage: environment.platform,
  onError: (event) => {
    if (event.originalError.name === 'HttpErrorResponse') {
      if (event.originalError.status === 403 || event.originalError.status === 404) {
        return false;
      }
    }

    return true;
  },
};
