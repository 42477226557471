import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Notif } from '@commons/notifications/notif';
import { NotifStatus } from '@commons/notifications/notif-status.enum';
import { NotificationsService } from '@commons/notifications/notifications.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animateToasts', [
      state('void', style({ opacity: 0 })),
      transition(':enter, :leave', [animate('0.3s ease')]),
    ]),
  ],
})
export class NotificationsComponent implements OnInit {
  notifications: Notif[] = [];
  NotifStatus = NotifStatus;
  defaultDuration = 5000;

  get position$(): BehaviorSubject<'top' | 'bottom'> {
    return this.notificationService.position;
  }

  constructor(
    readonly notificationService: NotificationsService,
    readonly cdr: ChangeDetectorRef,
    readonly domSanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.notificationService.changePosition('bottom');

    this.notificationService.notification.pipe(untilDestroyed(this)).subscribe((notification: Notif) => {
      this.updateNotification(notification);
    });

    this.notificationService.clearToasts.pipe(untilDestroyed(this)).subscribe(() => {
      this.clearToasts();
    });

    this.notificationService.clearLastToast.pipe(untilDestroyed(this)).subscribe(() => {
      this.clearLastToast();
    });
  }

  trackById(_: number, value: Notif): string {
    return value.id;
  }

  private updateNotification(notification: Notif) {
    notification.message = this.domSanitizer.sanitize(SecurityContext.HTML, notification.message);

    this.notifications.unshift(notification);
    this.cdr.detectChanges();

    setTimeout(() => {
      const index = this.notifications.indexOf(notification);
      this.notifications.splice(index, 1);
      this.cdr.detectChanges();
    }, notification.duration || this.defaultDuration);
  }

  removeNotification(index: number): void {
    this.notifications.splice(index, 1);
    this.cdr.detectChanges();
  }

  private clearLastToast() {
    this.notifications.pop();
    this.cdr.detectChanges();
  }

  private clearToasts() {
    this.notifications = [];
    this.cdr.detectChanges();
  }
}
