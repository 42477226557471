import { CommonModule, DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  NgModule,
  Output,
  ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { BreakpointsService } from '@core/services/breakpoints/breakpoints.service';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/feature-routing-enum';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { WINDOW } from '@wizbii-utils/angular/core';
import { trackEvent } from '@wizbii/utils/tracking';
import { Observable, fromEvent } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements AfterViewInit {
  menuOpen = false;

  isSticky$: Observable<boolean>;

  currentRoute: string;
  FeaturesRoutingEnum = FeaturesRoutingEnum;
  applicationId = environment.applicationId;

  trackEvent = trackEvent;

  @ViewChild('nav')
  nav: ElementRef;

  @Output() openMobileMenu: EventEmitter<boolean> = new EventEmitter();

  get isHome(): boolean {
    return this.currentRoute ? this.currentRoute.split('#')[0].split('?')[0] === '/' : true;
  }

  get isWeb$(): Observable<boolean> {
    return this.breakpointsService.isWeb$;
  }

  get isMobile$(): Observable<boolean> {
    return this.breakpointsService.isMobile$;
  }

  constructor(
    @Inject(DOCUMENT) private readonly document: any,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    @Inject(WINDOW) private readonly window: any,
    private readonly breakpointsService: BreakpointsService
  ) {}

  ngAfterViewInit(): void {
    this.isSticky$ = fromEvent(this.window, 'scroll', { passive: true }).pipe(
      map(() => this.nav.nativeElement.getBoundingClientRect().top <= 10),
      tap(() => {
        this.cdr.detectChanges();
      })
    );

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        this.currentRoute = event.url;
        this.cdr.detectChanges();
      });
  }

  changeMenuState(open: boolean): void {
    this.openMobileMenu.emit(open);
    this.menuOpen = open;
  }

  clickContactUs(): void {
    this.menuOpen = false;
    const mail = this.document.createElement('a');
    mail.href = 'mailto:contact@drive.wizbii.com?subject=WIZBII Drive - Contact';
    mail.click();
    mail.remove();
  }

  navLinkClick(menuOpen: boolean): void {
    if (menuOpen !== null) {
      this.menuOpen = menuOpen;
    }
  }
}

@NgModule({
  declarations: [HeaderComponent],
  imports: [CommonModule, MatIconModule, RouterModule],
  exports: [HeaderComponent],
})
export class HeaderModule {}
