import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BreakpointsService {
  readonly isMobile$: Observable<boolean>;
  readonly isWeb$: Observable<boolean>;
  readonly isTabletLandscape$: Observable<boolean>;
  readonly isTabletPortrait$: Observable<boolean>;
  readonly isMobileAndTabletPortrait$: Observable<boolean>;

  constructor(private readonly breakpointObserver: BreakpointObserver) {
    this.isMobile$ = this.breakpointObserver.observe([Breakpoints.Handset]).pipe(
      map((result) => result.matches),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.isWeb$ = this.breakpointObserver.observe([Breakpoints.Web]).pipe(
      map((result) => result.matches),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.isTabletLandscape$ = this.breakpointObserver.observe([Breakpoints.TabletLandscape]).pipe(
      map((result) => result.matches),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.isTabletPortrait$ = this.breakpointObserver.observe([Breakpoints.TabletPortrait]).pipe(
      map((result) => result.matches),
      distinctUntilChanged(),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.isMobileAndTabletPortrait$ = this.breakpointObserver
      .observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
      .pipe(
        map((result) => result.matches),
        distinctUntilChanged(),
        shareReplay({ bufferSize: 1, refCount: true })
      );
  }
}
