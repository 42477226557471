import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@wizbii-utils/angular/core';

@Injectable({ providedIn: 'root' })
export class RedirectSafeUrlService {
  constructor(@Inject(WINDOW) private readonly window: any) {}

  safeOpen(redirectUrl: string, envDomain: string, target: '_self' | '_blank' | '_parent' | '_top'): void {
    const parsedRedirectUrl = new URL(redirectUrl);
    if (
      parsedRedirectUrl.hostname.endsWith(`.${envDomain}`) ||
      // Jobs i18n urls
      parsedRedirectUrl.hostname === 'it.wizbii.com' ||
      parsedRedirectUrl.hostname === 'es.wizbii.com'
    ) {
      this.window.open(redirectUrl, target);
      return;
    }
    this.window.open(`https://${envDomain}`, target);
  }
}
