<ng-container *ngIf="(isMobile$ | async) === true">
  <div class="header-wrapper">
    <header>
      <a [routerLink]="['/']" (click)="trackEvent('Navigation', 'Click Logo')">
        <img
          class="navmenu__logo"
          [width]="152"
          src="https://storage.googleapis.com/wizbii/static-assets-v4/logos/SVG/RGB/WIZBII_Account.svg"
          alt="Logo WIZBII Account"
        />
      </a>
    </header>

    <nav [attr.arrow-nav]="false" disableRipple mat-tab-nav-bar>
      <a
        [routerLink]="['/', FeaturesRoutingEnum.Dashboard]"
        [active]="rlaDashboard.isActive"
        (click)="trackEvent('Navigation', 'Click Accueil')"
        #rlaDashboard="routerLinkActive"
        mat-tab-link
        routerLinkActive
        i18n
      >
        Accueil
      </a>

      <a
        [routerLink]="['/', FeaturesRoutingEnum.Profile]"
        [active]="rlaProfile.isActive"
        (click)="trackEvent('Navigation', 'Click Mon Profil')"
        #rlaProfile="routerLinkActive"
        mat-tab-link
        routerLinkActive
        i18n
      >
        Mon profil
      </a>

      <a
        [routerLink]="['/', FeaturesRoutingEnum.Privacy]"
        [active]="rlaPrivacy.isActive"
        (click)="trackEvent('Navigation', 'Click Confidentialité')"
        #rlaPrivacy="routerLinkActive"
        mat-tab-link
        routerLinkActive
        i18n
      >
        Confidentialité
      </a>

      <a
        [routerLink]="['/', FeaturesRoutingEnum.Security]"
        [active]="rlaSecurity.isActive"
        (click)="trackEvent('Navigation', 'Click Sécurité')"
        #rlaSecurity="routerLinkActive"
        mat-tab-link
        routerLinkActive
        i18n
      >
        Sécurité & Connexion
      </a>

      <a
        [routerLink]="['/', FeaturesRoutingEnum.Notifications]"
        [active]="rlaNotifications.isActive"
        (click)="trackEvent('Navigation', 'Click Notifications')"
        #rlaNotifications="routerLinkActive"
        mat-tab-link
        routerLinkActive
        i18n
      >
        Notifications
      </a>
    </nav>
  </div>

  <div class="content">
    <router-outlet />
  </div>
</ng-container>

<div *ngIf="(isMobile$ | async) === false">
  <div class="container" #container>
    <div class="menu">
      <app-sidebar />
    </div>
    <div class="content">
      <router-outlet />
    </div>
  </div>
</div>
