<!-- eslint-disable @angular-eslint/template/no-inline-styles -->
<wiz-header
  *ngIf="showInterservicesHeader$ | async"
  class="wiz-header"
  [attr.app]="(currentAppId$ | async) !== undefined ? (currentAppId$ | async) : appId"
  [attr.current]="(currentAppUniverse$ | async) !== undefined ? (currentAppUniverse$ | async) : 'emploi'"
  [attr.apidomain]="apiDomain"
  [attr.currentlocale]="locale"
  [attr.authapiurl]="authtApiUrl"
  [attr.accountapiurl]="accountApiUrl"
  cookiekey="wizbii_tokens"
/>

<!-- eslint-disable @angular-eslint/template/conditional-complexity -->
<main
  class="container"
  [style.marginTop.rem]="
    (bannerIsVisible$ | async) === true && (isMobile$ | async) === true
      ? '5'
      : (showInterservicesHeader$ | async) === false
        ? '0'
        : (isMobile$ | async) === true
          ? '1.75'
          : (isTabletPortrait$ | async) === true
            ? '3.125'
            : '0'
  "
  #container
>
  <div class="content">
    <router-outlet (activate)="onActivate($event)" />
  </div>

  <!-- eslint-disable @angular-eslint/template/no-inline-styles -->
  <wiz-footer
    *ngIf="!isLoginLoading"
    class="footer"
    [attr.contactemailkey]="contactEmailKey"
    [class.footer--not-home]="!isNotLoggedRoute"
    [style.marginTop]="marginTopFooter$ | async"
    [attr.appid]="appId"
    [attr.apidomain]="apiDomain"
    [attr.localeshandled]="localesHandled"
    [attr.currentlocale]="locale"
    [attr.contactapiurl]="contactApiUrl"
    [attr.accountapiurl]="accountApiUrl"
    (updateLocale)="updatePreferredLocale($event['detail'])"
  >
    <span slot="wzbFooterListInside">
      <li>
        <a
          [routerLink]="['/', FeaturesRoutingEnum.Profile]"
          [queryParams]="{ redirect: (profileUrl$ | async) }"
          (click)="trackEvent('Footer', 'Click Mon Profil')"
          i18n
          >Mon Profil</a
        >
      </li>

      <li>
        <a
          [routerLink]="['/', FeaturesRoutingEnum.Privacy]"
          [queryParams]="{ redirect: (privacyUrl$ | async) }"
          (click)="trackEvent('Footer', 'Click Confidentialité')"
          i18n
          >Confidentialité</a
        >
      </li>

      <li>
        <a
          [routerLink]="['/', FeaturesRoutingEnum.Security]"
          [queryParams]="{ redirect: (securityUrl$ | async) }"
          (click)="trackEvent('Footer', 'Click Sécurité')"
          i18n
          >Sécurité & Connexion</a
        >
      </li>

      <li>
        <a
          [routerLink]="['/', FeaturesRoutingEnum.Notifications]"
          [queryParams]="{ redirect: (notificationsUrl$ | async) }"
          (click)="trackEvent('Footer', 'Click Notifications')"
          i18n
          >Notifications</a
        >
      </li>
    </span>
  </wiz-footer>
</main>

<app-notifications *ngIf="(isMobile$ | async) === true" />
