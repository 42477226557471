import { DriveLangEnum } from '@models/drive/lang.enum';

export enum CodeType {
  Car = 'car',
  Exam = 'exam',
  Neph = 'neph',
  Package = 'package',
}
export enum Package {
  Full = 'full',
  Express = 'express',
  LaPoste = 'laposte',
  Submission = 'submission',
  NephSubmission = 'neph-submission',
}
export enum OtherPackage {
  Loyalty = 'loyalty',
  Partner = 'partner',
  Reference = 'reference',
  FreeMind = 'free_mind',
  Integral = 'integral',
}

export const Prices: Record<DriveLangEnum, Record<CodeType, Partial<Record<OtherPackage | Package, number>>>> = {
  [DriveLangEnum.fr]: {
    [CodeType.Car]: {
      [Package.Express]: 9.9,
      [Package.Full]: 24.9,
    },
    [CodeType.Exam]: {
      [Package.LaPoste]: 30,
    },
    [CodeType.Neph]: {
      [Package.Submission]: 30,
    },
    [CodeType.Package]: {
      [OtherPackage.Reference]: 49.9,
      [OtherPackage.FreeMind]: 60,
      [OtherPackage.Integral]: 74.9,
    },
  },
};

export const OffersNames: Record<DriveLangEnum, Record<CodeType, Partial<Record<OtherPackage | Package, string>>>> = {
  [DriveLangEnum.fr]: {
    [CodeType.Car]: {
      [Package.Express]: 'Révision express',
      [Package.Full]: 'Accès illimité',
    },
    [CodeType.Exam]: {
      [Package.LaPoste]: 'Code de la route avec La Poste',
    },
    [CodeType.Neph]: {
      [Package.Submission]: 'Dépôt du dossier NEPH',
    },
    [CodeType.Package]: {
      [OtherPackage.Reference]: 'Formule référence',
      [OtherPackage.FreeMind]: 'Formule esprit libre',
      [OtherPackage.Integral]: 'Formule intégrale',
    },
  },
};
