import { CommonModule, DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, NgModule, OnDestroy, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { SidebarModule } from '@commons/sidebar/sidebar.component';
import { BreakpointsService } from '@core/services/breakpoints/breakpoints.service';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/feature-routing-enum';
import { LocaleEnum } from '@models/commons/locales.enum';
import { trackEvent } from '@wizbii/utils/tracking';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';

@Component({
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardLayoutComponent implements OnDestroy {
  get isMobile$(): Observable<boolean> {
    return this.breakpointsService.isMobile$;
  }

  appId = environment.applicationId;
  apiDomain = environment.domain;
  localesHandled = Object.values(LocaleEnum);
  locale = environment.locale;
  trackEvent = trackEvent;

  FeaturesRoutingEnum = FeaturesRoutingEnum;

  cookieName = 'preferred-locale';
  cookieExpiryName = 'preferred-locale_expiry';

  @ViewChild('container')
  set containerElem(containerElem: any) {
    this.containerElem$.next(containerElem);
  }

  containerElem$ = new Subject<ElementRef>();

  constructor(
    private readonly breakpointsService: BreakpointsService,
    @Inject(DOCUMENT) private readonly document: any,
    private readonly cookieService: CookieService
  ) {}

  private getCookieDomain(): string {
    const cookieSubDomain = ['', ...this.document.location.hostname.split('.').slice(-2)].join('.');
    return cookieSubDomain === '.localhost' ? 'localhost' : `.${environment.domain}`;
  }

  updatePreferredLocale(locale: string): void {
    const cookieDomain = this.getCookieDomain();
    const expiryExists = this.cookieService.check(this.cookieExpiryName);
    const msIn390Days = 1000 * 3600 * 24 * 390;
    const expiry = expiryExists
      ? new Date(this.cookieService.get(this.cookieExpiryName))
      : new Date(Date.now() + msIn390Days);

    if (!expiryExists) {
      this.cookieService.set(
        this.cookieExpiryName,
        expiry.getTime().toString(),
        expiry,
        '/',
        cookieDomain,
        cookieDomain !== 'localhost',
        cookieDomain === 'localhost' ? 'Lax' : 'None'
      );
    }

    this.cookieService.set(
      this.cookieName,
      locale,
      expiry,
      '/',
      cookieDomain,
      cookieDomain !== 'localhost',
      cookieDomain === 'localhost' ? 'Lax' : 'None'
    );
  }

  ngOnDestroy(): void {
    this.containerElem$.complete();
  }
}

@NgModule({
  declarations: [DashboardLayoutComponent],
  imports: [CommonModule, RouterModule, MatIconModule, MatTabsModule, MatSidenavModule, MatListModule, SidebarModule],
  exports: [DashboardLayoutComponent],
})
export class DashboardLayoutModule {}
