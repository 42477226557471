import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BreakpointsService } from '@core/services/breakpoints/breakpoints.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-context-account',
  templateUrl: './context-account.component.html',
  styleUrls: ['./context-account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextAccountComponent {
  get isMobileAndTabletPortrait$(): Observable<boolean> {
    return this.breakpointsService.isMobileAndTabletPortrait$;
  }

  constructor(private readonly breakpointsService: BreakpointsService) {}
}

@NgModule({
  imports: [CommonModule, MatIconModule],
  declarations: [ContextAccountComponent],
  exports: [ContextAccountComponent],
})
export class ContextAccountModule {}
