import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { FeaturesRoutingEnum } from '@features/feature-routing-enum';
import { Store } from '@ngxs/store';
import { SessionState } from '@stores/session/session.state';
import { Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';

@Injectable()
export class MustBeConnectedGuard {
  constructor(
    private readonly store: Store,
    private readonly router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return this.store.select(SessionState.isInitialized).pipe(
      filter((isInitialized) => isInitialized),
      first(),
      switchMap(() => this.store.selectOnce(SessionState.tokens).pipe(map((tokens) => !!tokens))),
      map((isLogged) => {
        if (!isLogged) {
          this.router.navigate(['/', FeaturesRoutingEnum.SignIn], {
            queryParams: route.queryParams,
            queryParamsHandling: 'merge',
          });

          return false;
        }

        return true;
      })
    );
  }
}
