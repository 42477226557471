<nav
  class="nav"
  [class.nav--open]="menuOpen"
  [class.nav--white-shadowed]="(isMobile$ | async) === true && !isHome"
  [class.nav--sticky]="(isSticky$ | async) === true"
  #nav
>
  <div class="nav__header" [class.nav__header--open]="menuOpen">
    <a class="logo" (click)="navLinkClick(false)" routerLink="/" queryParamsHandling="preserve">
      <img
        [width]="(isMobile$ | async) === true ? 152 : 216"
        [class.logo--black]="!isHome || menuOpen"
        src="https://storage.googleapis.com/wizbii/static-assets-v4/logos/SVG/RGB/WIZBII_Account.svg"
        alt="Logo WIZBII Account"
      />
    </a>

    <!-- eslint-disable @angular-eslint/template/no-inline-styles -->
    <mat-icon
      *ngIf="!menuOpen && (isMobile$ | async) === true"
      class="burger"
      [style.color]="'black'"
      [class.burger--black]="!isHome || menuOpen"
      (click)="menuOpen = true"
      svgIcon="burger"
      width="1.25em"
      height="1.035625em"
    />

    <button *ngIf="menuOpen" class="nav__header__cross" (click)="menuOpen = false" type="button">
      <mat-icon svgIcon="cross" width="1.0625em" height="1.0625em" />
    </button>
  </div>

  <div *ngIf="(isWeb$ | async) === true" class="nav__auth" [class.nav__auth--open]="menuOpen">
    <a
      class="wzb-btn btn-signup"
      [routerLink]="['/' + FeaturesRoutingEnum.SignUp]"
      (click)="navLinkClick(null)"
      queryParamsHandling="preserve"
      i18n
    >
      Créer un compte
    </a>

    <a
      class="wzb-btn btn--outline-white btn-signin"
      [routerLink]="['/', FeaturesRoutingEnum.SignIn]"
      [class.btn-signin--dark]="!isHome"
      (click)="navLinkClick(null)"
      i18n
    >
      Connexion
    </a>
  </div>
</nav>
