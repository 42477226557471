<section *ngIf="coupon$ | async as coupon" class="context-drive-influencer">
  <ng-container *ngIf="(isMobileAndTabletPortrait$ | async) === true">
    <p class="context-drive-influencer__pop-up">
      <ng-container i18n> Entrez le code</ng-container>
      <span class="ticket__text">{{ coupon.id }}</span>

      <ng-container *ngIf="couponOfferName$ | async as couponOfferName">
        <ng-container *ngIf="couponOfferName" i18n>
          pour bénéficier de la formule "{{ couponOfferName }}" à</ng-container
        >
        <ng-container *ngIf="!couponOfferName" i18n> pour bénéficier d'une réduction de</ng-container>

        <span
          *ngIf="couponPrice$ | async as couponPrice"
          class="context-drive-influencer__subtitle context-drive-influencer__subtitle__price highlighted--blue"
        >
          <ng-container *ngIf="coupon.amountOff && couponPrice">{{
            couponPrice | currency: 'EUR' : 'symbol' : '1.2-2' : lang
          }}</ng-container>
          <ng-container *ngIf="coupon.amountOff && !couponPrice">-{{ coupon.amountOff }}€</ng-container>
          <ng-container *ngIf="coupon.percentOff && !couponPrice">-{{ coupon.percentOff }}%</ng-container>
          <ng-container *ngIf="coupon.percentOff">{{
            couponPrice | currency: 'EUR' : 'symbol' : '1.2-2' : lang
          }}</ng-container>
        </span>

        <ng-container *ngIf="coupon.endDate" i18n>jusqu'au {{ coupon.endDate | date: 'dd/MM/yyyy' }}</ng-container
        >&nbsp;!&nbsp;🎉
      </ng-container>
    </p>
  </ng-container>

  <ng-container *ngIf="(isMobileAndTabletPortrait$ | async) === false">
    <h2 class="context-drive-influencer__title" i18n>
      Utilisez vite votre
      <span class="highlighted--white">code promo*&nbsp;:</span>
    </h2>

    <div class="ticket">
      <span class="ticket__deduced-price"
        >-&nbsp;{{ coupon.amountOff | currency: 'EUR' : 'symbol' : '1.0-2' : lang }}</span
      >
      <div class="ticket__content" i18n>
        <span class="ticket__text">{{ coupon.id }}</span>
        <p class="ticket__text--small">
          -&nbsp;{{ coupon.amountOff | currency: 'EUR' : 'symbol' : '1.0-2' : lang }} sur la formule 12 mois
        </p>
      </div>
    </div>

    <ng-container *ngIf="couponOfferName$ | async as couponOfferName">
      <p class="context-drive-influencer__subtitle">
        <ng-container *ngIf="couponOfferName" i18n>
          pour bénéficier de la formule "{{ couponOfferName }}" à</ng-container
        >
        <ng-container *ngIf="!couponOfferName" i18n> pour bénéficier d'une réduction de</ng-container>
      </p>

      <span
        *ngIf="couponPrice$ | async as couponPrice"
        class="context-drive-influencer__subtitle context-drive-influencer__subtitle__price highlighted--white"
      >
        <ng-container *ngIf="coupon.amountOff && couponPrice">{{
          couponPrice | currency: 'EUR' : 'symbol' : '1.2-2' : lang
        }}</ng-container>
        <ng-container *ngIf="coupon.amountOff && !couponPrice">-{{ coupon.amountOff }}€</ng-container>
        <ng-container *ngIf="coupon.percentOff && !couponPrice">-{{ coupon.percentOff }}%</ng-container>
        <ng-container *ngIf="coupon.percentOff">{{
          couponPrice | currency: 'EUR' : 'symbol' : '1.2-2' : lang
        }}</ng-container>
      </span>
    </ng-container>

    <p *ngIf="couponOfferName$ | async as couponOfferName" class="context-drive-influencer__conditions">
      <ng-container *ngIf="couponBasePrice$ | async as couponBasePrice">
        <ng-container *ngIf="coupon.endDate" i18n>
          Offre non cumulable, valable jusqu'au {{ coupon.endDate | date: 'dd/MM/yyyy' }} (inclus), pour tout achat
          d’une formule "{{ couponOfferName }}" d’une valeur de
          {{ couponBasePrice | currency: 'EUR' : 'symbol' : '1.2-2' : lang }} sur le site https://drive.wizbii.com/ en
          insérant le code “{{ coupon.id }}” dans le champ “Code promotionnel” de la page de paiement.
        </ng-container>

        <ng-container *ngIf="!coupon.endDate" i18n>
          Offre non cumulable, pour tout achat d’une formule "{{ couponOfferName }}" d’une valeur de
          {{ couponBasePrice | currency: 'EUR' : 'symbol' : '1.2-2' : lang }} sur le site https://drive.wizbii.com/ en
          insérant le code “{{ coupon.id }}” dans le champ “Code promotionnel” de la page de paiement.
        </ng-container>
      </ng-container>
    </p>
  </ng-container>
</section>
