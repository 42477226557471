import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { RedirectSafeUrlService } from '@core/services/redirect-safe-url/redirect-safe-url.service';
import { environment } from '@environment';
import { FeaturesRoutingEnum } from '@features/feature-routing-enum';
import { Store } from '@ngxs/store';
import { SessionState } from '@stores/session/session.state';
import { WINDOW } from '@wizbii-utils/angular/core';
import { JwtTokens } from '@wizbii-utils/angular/jwt';
import { AccountWebservice } from '@wizbii-utils/angular/webservices';
import qs from 'qs';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, first, map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LoginTokenGuard {
  constructor(
    private readonly accountWebservice: AccountWebservice,
    private readonly router: Router,
    private readonly store: Store,
    private readonly redirectSafeUrlService: RedirectSafeUrlService,
    @Inject(WINDOW) private readonly window: any
  ) {}

  canActivate(next: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const loginToken = next.queryParamMap.get('login-token');

    if (!loginToken) {
      return true;
    }

    const redirect = next.queryParamMap.get('redirect');

    return this.store.select(SessionState.isInitialized).pipe(
      filter((isInitialized) => isInitialized),
      first(),
      switchMap(() => this.store.selectOnce(SessionState.tokens).pipe(map((tokens) => !!tokens))),
      switchMap((isLogged) => {
        if (isLogged && redirect) {
          this.redirectSafeUrlService.safeOpen(redirect, environment.domainsByLocale[environment.locale], '_self');
          return of(false);
        }
        const url = `${this.window.location.origin}${this.window.location.pathname}`;
        const queryParamsActual = qs.parse(this.window.location.search.split('?')[1]);
        const queryParams: any = Object.keys(queryParamsActual).reduce(
          (acc, current) => ({
            ...acc,
            ...(current !== 'login-token' ? { [current]: queryParamsActual[current] } : {}),
          }),
          {} as any
        );
        const queryParamsStr = qs.stringify(queryParams);
        const finalUrl = `${url}?${queryParamsStr}`;

        if (isLogged) {
          this.window.open(finalUrl, '_self');
          return of(false);
        }

        return this.store.select(SessionState.currentAppId).pipe(
          switchMap((appId) => this.accountWebservice.loginWithToken(loginToken, appId, { withCredentials: true })),
          switchMap((_jwtTokens: JwtTokens) => {
            this.window.open(finalUrl, '_self');
            return of(true);
          }),
          catchError((error) => {
            if (error.status === 404) {
              this.router.navigate(['/', FeaturesRoutingEnum.SignIn], { queryParams: { redirect } });
            }
            return throwError(error);
          })
        );
      })
    );
  }
}
