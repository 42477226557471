import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RedirectSafeUrlService } from '@core/services/redirect-safe-url/redirect-safe-url.service';
import { environment } from '@environment';
import { LocaleEnum } from '@models/commons/locales.enum';
import { Select, Store } from '@ngxs/store';
import { ServicesState } from '@stores/services/services.state';
import { SessionState } from '@stores/session/session.state';
import { WINDOW } from '@wizbii-utils/angular/core';
import { AccountWebservice, Options } from '@wizbii-utils/angular/webservices';
import { City, CountryService } from '@wizbii/utils/models';
import { Observable, combineLatest, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, filter, first, map, switchMap, tap } from 'rxjs/operators';

export interface InputForm {
  name: string;
  required: boolean;
}

export enum RegisterFormStatutEnum {
  serviceActivation = 'serviceActivation',
  newRegister = 'newRegister',
}

@Injectable({ providedIn: 'root' })
export class FormWebservice {
  @Select(ServicesState.accountI18nConfig)
  accountI18nConfig$: Observable<CountryService>;

  private readonly baseUrl: string;

  constructor(
    private readonly http: HttpClient,
    @Inject(WINDOW) private readonly window: any,
    private readonly store: Store,
    private readonly route: ActivatedRoute,
    private readonly accountWebservice: AccountWebservice,
    private readonly redirectSafeUrlService: RedirectSafeUrlService,
    @Inject(PLATFORM_ID) platformId: any
  ) {
    if (isPlatformBrowser(platformId)) {
      this.baseUrl = `/_api/account-api`;
    } else {
      this.baseUrl = `http://account-api`;
    }
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  getForm(
    serviceId: string,
    registerFormStatut: RegisterFormStatutEnum,
    redirectUrl?: string
  ): Observable<InputForm[]> {
    return registerFormStatut === RegisterFormStatutEnum.newRegister
      ? this.http.get<InputForm[]>(`${this.baseUrl}/v1/register-form/${serviceId}`)
      : this.http.get<any>(`${this.baseUrl}/v1/activating/${serviceId}`).pipe(
          catchError((error) => {
            const realError = error instanceof HttpErrorResponse && error?.status ? error : error?.error;

            if (
              (realError.code >= 400 && realError.code < 500) ||
              (realError.status >= 400 && realError.status < 500)
            ) {
              return combineLatest([
                this.store.select(SessionState.userId).pipe(
                  filter((userId) => !!userId),
                  first()
                ),
                this.route.queryParamMap.pipe(map((queryParamMap) => queryParamMap.get('source'))),
              ]).pipe(
                switchMap(([userId, source]) =>
                  combineLatest([
                    source ? this.accountWebservice.setSource(userId, source) : of({}),
                    this.accountI18nConfig$.pipe(
                      filter((accountI18nConfig) => !!accountI18nConfig),
                      distinctUntilChanged()
                    ),
                  ])
                ),
                tap(([_, accountI18nConfig]) => {
                  if (redirectUrl) {
                    this.redirectSafeUrlService.safeOpen(
                      redirectUrl,
                      environment.domainsByLocale[environment.locale],
                      '_self'
                    );
                    return;
                  }

                  this.window.open(accountI18nConfig.serviceUri, '_self');
                })
              );
            }

            return throwError(realError);
          })
        );
  }

  activateService(
    serviceId: string,
    account: {
      firstName: string;
      lastName: string;
      locale?: LocaleEnum;
      dateBirthday?: Date | string;
      location?: City;
      mobile?: string;
    },
    options?: Options
  ): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/v1/activate/${serviceId}`, account, options);
  }
}
