import { Injectable } from '@angular/core';
import { ICallbackConfig, Notif } from '@commons/notifications/notif';
import { NotifStatus } from '@commons/notifications/notif-status.enum';
import { BehaviorSubject, ReplaySubject } from 'rxjs';

@Injectable()
export class NotificationsService {
  private readonly _notification: ReplaySubject<Notif> = new ReplaySubject<Notif>();
  private readonly _clearToasts: ReplaySubject<void> = new ReplaySubject<void>();
  private readonly _clearLastToast: ReplaySubject<void> = new ReplaySubject<void>();
  private readonly _position = new BehaviorSubject<'top' | 'bottom'>('top');

  get notification(): ReplaySubject<Notif> {
    return this._notification;
  }

  get clearToasts(): ReplaySubject<void> {
    return this._clearToasts;
  }

  get clearLastToast(): ReplaySubject<void> {
    return this._clearLastToast;
  }

  get position(): BehaviorSubject<'top' | 'bottom'> {
    return this._position;
  }

  createToast(message: string, style?: NotifStatus, duration?: number, callbackConfig?: ICallbackConfig): void {
    this.notification.next(new Notif({ message, style, duration, callbackConfig }));
  }

  changePosition(position?: 'top' | 'bottom'): void {
    this.position.next(position);
  }

  clear(): void {
    this.clearToasts.next();
  }

  clearLast(): void {
    this.clearLastToast.next();
  }
}
